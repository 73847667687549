import { MsalProvider } from '@azure/msal-react';
import { createStandaloneToast, KlikProvider, theme } from '@lego/klik-ui';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { IntlContextProvider } from './i18n/intl/intl-context';
import { IntlProvider } from './i18n/intl/intl-provider';
import { AppRouter } from './routes/app-router';
import { AuthenticationGate } from './security/authentication-gate';
import { msalInstance } from './security/msal';

const { ToastContainer } = createStandaloneToast({ theme });
const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <KlikProvider includeFont>
      <ToastContainer />
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <AuthenticationGate>
            <QueryClientProvider client={queryClient}>
              <IntlProvider>
                <IntlContextProvider>
                  <AppRouter />
                </IntlContextProvider>
              </IntlProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </AuthenticationGate>
        </MsalProvider>
      </BrowserRouter>
    </KlikProvider>
  </React.StrictMode>,
);

import { Text } from '@lego/klik-ui';
import React from 'react';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { useFinishedMaterialRequests } from '../../queries/finished-material-requests';
import { MaterialRequestListItem } from '../material-request-list-item/material-request-list-item';
import { Spinner } from '../spinner/spinner';

export const FinishedMaterialRequestList: React.FC = () => {
  const { data, isLoading } = useFinishedMaterialRequests();

  if (isLoading) return <Spinner isIndeterminate />;

  if (data === undefined) return null;

  if (data.length <= 0)
    return (
      <Text>
        <FormattedMessage id="finishedMaterialRequestList.noFinishedRequests" />
      </Text>
    );

  return (
    <>
      {data.map((r) => (
        <MaterialRequestListItem key={r.Id} request={r} />
      ))}
    </>
  );
};

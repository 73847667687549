import { nameof } from '../utils/nameof';
import * as sapApi from './api/sap-api';
import { buildSapApiUri } from './api/sap-api';
import { ISAPResultSetResponse, IVendorsSearch, VENDORS_SERVICE } from './sap-result-types';

let searchVendorsAbortController: AbortController | undefined;

export const searchVendors = async (
  searchText: string,
  abortPrevious = false,
): Promise<IVendorsSearch[]> => {
  if (abortPrevious && searchVendorsAbortController) searchVendorsAbortController.abort();
  searchVendorsAbortController = new AbortController();

  const searchString = `search='*${searchText.split(' ').join('*')}*'`;

  const selectString = `${nameof<IVendorsSearch>('Name')},${nameof<IVendorsSearch>(
    'SupplierNumber',
  )}`;

  const uri = buildSapApiUri(VENDORS_SERVICE, undefined, selectString, undefined, searchString);

  try {
    const response = await sapApi.get<ISAPResultSetResponse<IVendorsSearch>>(
      uri,
      searchVendorsAbortController,
    );

    return response.d.results;
  } catch (error) {
    if ((error as Error).message === 'No data found') return [];
    throw error;
  }
};

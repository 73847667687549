import { LanguageCode } from '../utils/languages';
import translations_cs from './assets/cs-CZ.json';
import translations_da from './assets/da-DK.json';
import translations_en from './assets/en-US.json';
import translations_es from './assets/es-MX.json';
import translations_hu from './assets/hu-HU.json';
import translations_zh from './assets/zh-CN.json';

export const getTranslations = (language: string) => {
  switch (language) {
    case LanguageCode.Danish:
      return translations_da;
    case LanguageCode.Czech:
      return translations_cs;
    case LanguageCode.Spanish:
      return translations_es;
    case LanguageCode.Hungarian:
      return translations_hu;
    case LanguageCode.Chinese:
      return translations_zh;
    case LanguageCode.English:
    default:
      return translations_en;
  }
};

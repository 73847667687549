import { Box, Dropdown, Flex, Text } from '@lego/klik-ui';
import React, { useState } from 'react';
import { MaterialRequestRole } from '../../enums/material-request-role';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { usePendingMaterialRequests } from '../../queries/pending-material-requests';
import { useProfile } from '../../queries/profile';
import { userHasRole } from '../../utils/user-has-role';
import { MaterialRequestListItem } from '../material-request-list-item/material-request-list-item';
import { Spinner } from '../spinner/spinner';

export const PendingMaterialRequestList: React.FC = () => {
  const { data: profile } = useProfile();

  const hasApproverRole = userHasRole(MaterialRequestRole.Approver);

  const userPlant = profile?.Plant ?? '500';

  const [selectedPlant, setSelectedPlant] = useState(userPlant);
  const { data, isLoading } = usePendingMaterialRequests();

  const filteredPendingRequests = hasApproverRole
    ? data?.filter((request) => request.Plant === selectedPlant)
    : data;

  const plants = [
    ...new Set([...(data ?? []).map((v) => v.Plant), userPlant].filter((v) => v).sort()),
  ];

  if (isLoading) return <Spinner isIndeterminate />;

  if (data === undefined) return null;

  return (
    <Flex flexDirection="column">
      {hasApproverRole && (
        <Flex alignItems="center" alignSelf="end">
          <FormattedMessage id="pendingMaterialRequestList.filterByPlant" />
          <Box marginLeft="8px">
            <Dropdown
              defaultValue={[selectedPlant]}
              id="plant"
              onChange={(plant) => {
                if (plant) setSelectedPlant(plant);
              }}
              size="sm"
              width="120px"
            >
              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition*/}
              {plants.map((plant) => (
                <Dropdown.Option key={plant} value={plant}>
                  {plant}
                </Dropdown.Option>
              )) ?? []}
            </Dropdown>
          </Box>
        </Flex>
      )}
      {filteredPendingRequests?.map((r) => (
        <MaterialRequestListItem key={r.Id} request={r} />
      ))}
      {filteredPendingRequests?.length === 0 && (
        <Text>
          <FormattedMessage id="pendingMaterialRequestList.noPendingRequests" />
        </Text>
      )}
    </Flex>
  );
};

import { nameof } from '../utils/nameof';
import * as sapApi from './api/sap-api';
import { buildSapApiUri } from './api/sap-api';
import {
  IManufacturersSearch,
  ISAPResultSetResponse,
  MANUFACTURERS_SERVICE,
} from './sap-result-types';

let searchManufacturersAbortController: AbortController | undefined;

export const searchManufacturers = async (
  searchText: string,
  abortPrevious = false,
): Promise<IManufacturersSearch[]> => {
  if (abortPrevious && searchManufacturersAbortController)
    searchManufacturersAbortController.abort();
  searchManufacturersAbortController = new AbortController();

  const searchString = `search='*${searchText.split(' ').join('*')}*'`;

  const selectString = nameof<IManufacturersSearch>('Name');

  const uri = buildSapApiUri(
    MANUFACTURERS_SERVICE,
    undefined,
    selectString,
    undefined,
    searchString,
  );

  try {
    const response = await sapApi.get<ISAPResultSetResponse<IManufacturersSearch>>(
      uri,
      searchManufacturersAbortController,
    );

    return response.d.results;
  } catch (error) {
    if ((error as Error).message === 'No data found') return [];
    throw error;
  }
};

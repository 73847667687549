import { Spacer } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import {
  Box,
  Button,
  Drawer,
  Dropdown,
  Flex,
  LEGOLogo,
  useDisclosure,
  useToast,
} from '@lego/klik-ui';
import { MenuNavigationVertical } from '@lego/klik-ui-icons';
import { Navbar } from '@lego/klik-ui-navbar';
import { Text } from '@lego/klik-ui-text';
import React, { useContext } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { MaterialRequestRole } from '../../enums/material-request-role';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { IntlContext } from '../../i18n/intl/intl-context';
import { useUpdateProfile } from '../../queries/mutations/update-profile';
import { useProfile } from '../../queries/profile';
import { RoutePaths } from '../../routes/app-router';
import { msalInstance } from '../../security/msal';
import { browserLanguage, languages } from '../../utils/languages';
import { ScreenSize } from '../../utils/screen-size';
import { userHasRole } from '../../utils/user-has-role';
import { ProfilePhoto } from '../profile-photo/profile-photo';
import { showToast } from '../toast/show-toast';

export const NavBar: React.FC = () => {
  const intl = useContext(IntlContext);
  const toast = useToast();

  const account = msalInstance.getActiveAccount();

  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  const openDrawerBtnRef = React.useRef(null);

  const { data: profile } = useProfile();

  const userLanguage = profile?.Language ?? browserLanguage;

  const [isMobileView] = useMediaQuery(`(max-width: ${ScreenSize.MIN_WIDTH_TABLET})`);

  const onUpdateProfileError = (error?: string) => {
    if (error) {
      showToast(toast, 'top-right', {
        title: 'Update profile',
        description: error,
        variant: 'error',
      });
    }
  };

  const { mutate: updateProfile } = useUpdateProfile(onUpdateProfileError);

  const host = document.location.host;

  let environment: string | undefined;

  if (host.startsWith('localhost')) {
    environment = 'Localhost';
  } else if (host.startsWith('newmat-dev')) {
    environment = 'Development';
  } else if (host.startsWith('newmat-staging')) {
    environment = 'Staging';
  } else {
    environment = undefined;
  }

  const onLanguageChange = (language: string) => {
    updateProfile({ Plant: profile?.Plant ?? '', Language: language });
  };

  const navItemAppLogo = () => (
    <Navbar.Brand as={RouteLink} to="/">
      <LEGOLogo marginRight="24px" />
      <Text>
        <FormattedMessage id="app.name" />
      </Text>
    </Navbar.Brand>
  );

  const navItemLinks = () =>
    userHasRole(MaterialRequestRole.Requester) &&
    profile?.Plant && (
      <Navbar.Item as={RouteLink} onClick={onDrawerClose} to={RoutePaths.RequestPath}>
        <FormattedMessage id="app.newRequest" />
      </Navbar.Item>
    );

  const navItemProfilePicture = () => (
    <Navbar.Item as={RouteLink} onClick={onDrawerClose} to={RoutePaths.ProfilePath}>
      <ProfilePhoto
        clipPath="circle(40%)"
        height="64px"
        title={
          account?.name
            ? intl.formatMessage({ id: 'navbar.loggedInAs' }, { username: account.name })
            : ''
        }
        width="64px"
      />
    </Navbar.Item>
  );

  const navItemEnvironment = () =>
    environment && (
      <Navbar.Item>
        <Text backgroundColor="error.500" borderRadius="12px" color="black" padding="5px">
          {environment}
        </Text>
      </Navbar.Item>
    );

  const navItemLanguageSelector = () =>
    userLanguage && (
      <Dropdown
        defaultValue={[userLanguage]}
        id="appLanguage"
        onChange={(value) => onLanguageChange(value)}
        size="sm"
        width="138px"
      >
        {languages.map((language) => (
          <Dropdown.Option key={language.code} value={language.code}>
            {language.name}
          </Dropdown.Option>
        ))}
      </Dropdown>
    );

  return (
    <Navbar justifyContent={isMobileView ? 'space-between' : 'unset'}>
      {navItemAppLogo()}
      {!isMobileView && (
        <>
          <Navbar.Segment>{navItemLinks()}</Navbar.Segment>
          <Spacer />
          <Navbar.Segment>
            {navItemEnvironment()}
            {navItemLanguageSelector()}
            {navItemProfilePicture()}
          </Navbar.Segment>
        </>
      )}
      <Navbar.Segment>
        {isMobileView && (
          <Button color="white" onClick={onDrawerOpen} ref={openDrawerBtnRef} variant="ghost">
            <MenuNavigationVertical />
          </Button>
        )}
        <Drawer
          finalFocusRef={openDrawerBtnRef}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          placement="left"
        >
          <Drawer.Overlay>
            <Box bgColor="white" height="100%" padding="24px">
              {navItemAppLogo()}
              <Box marginTop="24px">
                <Flex alignItems="center">
                  {navItemProfilePicture()}
                  {navItemEnvironment()}
                </Flex>
                <Box margin="24px 0">{navItemLanguageSelector()}</Box>
                {navItemLinks()}
              </Box>
              <Drawer.CloseButton />
            </Box>
          </Drawer.Overlay>
        </Drawer>
      </Navbar.Segment>
    </Navbar>
  );
};

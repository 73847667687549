import axios, { AxiosError } from 'axios';
import { getEnv } from '../../env';
import { NotFoundError } from '../errors/not-found-error';
import { addAuthorizationInterceptor } from '../utils/axios-authorization-interceptor';

const axiosInstance = axios.create({
  timeout: parseInt(getEnv.SERVICE_API_TIMEOUT),
  baseURL: getEnv.BACKEND_MATERIAL_CREATION_API_BASE_URI,
});

addAuthorizationInterceptor(
  axiosInstance,
  getEnv.LEGO_AUTH_MATERIAL_CREATION_API_SCOPES.split(','),
);

export const get = async <TReturn extends object>(
  uri: string,
  abortController?: AbortController,
): Promise<TReturn> => {
  return axiosInstance
    .get<TReturn>(uri, {
      signal: abortController ? abortController.signal : undefined,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError<string>) => {
      const errorMessage = error.response?.data;
      if (error.response?.status === 404) throw new NotFoundError(errorMessage);
      throw errorMessage ? new Error(errorMessage) : error;
    });
};

export const post = async <TData extends object, TReturn extends object>(
  uri: string,
  data?: TData,
  abortController?: AbortController,
): Promise<TReturn> => {
  return axiosInstance
    .post<TReturn>(uri, data ? JSON.stringify(data) : undefined, {
      headers: { 'Content-Type': 'application/json' },
      signal: abortController ? abortController.signal : undefined,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError<string>) => {
      const errorMessage = error.response?.data;
      throw errorMessage ? new Error(errorMessage) : error;
    });
};

export const put = async <TData extends object, TReturn extends object>(
  uri: string,
  data: TData,
  abortController?: AbortController,
): Promise<TReturn> => {
  return axiosInstance
    .put<TReturn>(uri, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' },
      signal: abortController ? abortController.signal : undefined,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError<string>) => {
      const errorMessage = error.response?.data;
      throw errorMessage ? new Error(errorMessage) : error;
    });
};

export const getFile = async (uri: string): Promise<Blob> => {
  return axiosInstance
    .get<Blob>(uri, { headers: { Accept: '*/*' }, responseType: 'blob' })
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (error.response && error.response.status === 404) throw new NotFoundError();
      throw error;
    });
};

export const addFile = async (uri: string, data: Blob): Promise<boolean> => {
  const response = await axiosInstance.put(uri, data, {
    headers: { 'Content-Type': data.type },
  });

  return response.status === 200;
};

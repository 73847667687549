import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { PropsValue, SingleValue } from 'react-select';
import { IMaterialRequest } from '../../../models/material-request';
import {
  IAsyncSelectOption,
  SearchDropdown,
  TLoadOptions,
} from '../../search-dropdown/search-dropdown';
import { IFieldFormControl } from './form-control';
import { FormControlContainer } from './form-control-container';
import { FormControlError } from './form-control-error';

interface ISearchDropdownFormControlProps extends IFieldFormControl {
  placeholder: string;
  defaultValue?: string;
  loadOptions: TLoadOptions;
  onValueChange?: (selectedOption: SingleValue<IAsyncSelectOption>) => void;
}

export const SearchDropdownFormControl: React.FC<ISearchDropdownFormControlProps> = (props) => {
  const { fieldName, errorMessage, placeholder, defaultValue, loadOptions, onValueChange } = props;

  const { errors, touched, isValidating, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<IMaterialRequest>();

  const [searchValue, setSearchValue] = useState<PropsValue<IAsyncSelectOption> | undefined>(
    undefined,
  );

  useEffect(() => {
    if (defaultValue)
      setSearchValue({
        value: defaultValue,
        label: defaultValue,
      });
  }, [defaultValue]);

  const formSetFieldValue = async (field: string, value: string) => {
    // setFieldValue uses setState internally which is async
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await setFieldValue(field, value);
    setFieldTouched(field);
    handleChange(field);
  };

  const isInvalid = () =>
    (!!errors[fieldName] && !!touched[fieldName] && !isValidating) || !!errorMessage;

  const onChange = async (selectedOption: SingleValue<IAsyncSelectOption>) => {
    if (selectedOption) {
      await formSetFieldValue(fieldName, selectedOption.value);
      if (onValueChange) onValueChange(selectedOption);
    } else {
      setSearchValue(null);
      await formSetFieldValue(fieldName, '');
    }
  };

  return (
    <FormControlContainer {...props}>
      <SearchDropdown
        errorMessage={errorMessage}
        fieldName={fieldName}
        fontSize="14px"
        isInvalid={isInvalid()}
        loadOptions={loadOptions}
        margin="0"
        onValueChange={onChange}
        padding="0"
        placeholder={placeholder}
        selectedTextColor="inherit"
        value={searchValue}
      />
      <FormControlError {...props} />
    </FormControlContainer>
  );
};

import { AxiosInstance } from 'axios';
import { refreshTokens } from '../../security/auth';

export const addAuthorizationInterceptor = (axiosInstance: AxiosInstance, authScopes: string[]) => {
  axiosInstance.interceptors.request.use(async (config) => {
    try {
      const { accessToken } = await refreshTokens(authScopes);
      const authHeader = { Authorization: `Bearer ${accessToken}` };

      if (config.headers !== undefined) {
        config.headers = { ...config.headers, ...authHeader };
      } else {
        config.headers = authHeader;
      }

      return config;
    } catch (error) {
      throw error;
    }
  });
};

import { v4 as uuidv4 } from 'uuid';
import { getFileExtension } from '../utils/get-file-extension';
import { addFile } from './api/material-creation-api';

export const addMaterialRequestFile = async (file: File): Promise<string> => {
  const fileId = uuidv4();
  const fileExtension = getFileExtension(file.name);
  const filename = [fileId, fileExtension].join('.');
  const uri = `files/${filename}`;

  const isAdded = await addFile(uri, file);

  if (!isAdded) throw new Error('Error adding file');

  return filename;
};

import axios from 'axios';
import { getEnv } from '../../env';
import { addAuthorizationInterceptor } from '../utils/axios-authorization-interceptor';

const axiosInstance = axios.create({
  timeout: parseInt(getEnv.SERVICE_API_TIMEOUT),
  baseURL: 'https://graph.microsoft.com/v1.0',
});

addAuthorizationInterceptor(axiosInstance, getEnv.LEGO_AUTH_MS_GRAPH_API_SCOPES.split(','));

export const getImage = async (uri: string): Promise<Blob> => {
  return axiosInstance
    .get<Blob>(uri, {
      responseType: 'blob',
    })
    .then((response) => {
      return response.data;
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const getEnv = {
  SERVICE_API_TIMEOUT: process.env.REACT_APP_SERVICE_API_TIMEOUT as string,
  LEGO_AUTH_MS_GRAPH_API_SCOPES: process.env.REACT_APP_LEGO_AUTH_MS_GRAPH_API_SCOPES as string,
  BACKEND_SAP_API_BASE_URI: process.env.REACT_APP_BACKEND_SAP_API_BASE_URI as string,
  BACKEND_MATERIAL_CREATION_API_BASE_URI: process.env
    .REACT_APP_BACKEND_MATERIAL_CREATION_API_BASE_URI as string,
  LEGO_AUTH_CLIENT_ID: process.env.REACT_APP_LEGO_AUTH_CLIENT_ID as string,
  LEGO_AUTH_SCOPES: process.env.REACT_APP_LEGO_AUTH_SCOPES as string,
  LEGO_AUTH_SAP_API_SCOPES: process.env.REACT_APP_LEGO_AUTH_SAP_API_SCOPES as string,
  LEGO_AUTH_MATERIAL_CREATION_API_SCOPES: process.env
    .REACT_APP_LEGO_AUTH_MATERIAL_CREATION_API_SCOPES as string,
  LEGO_AUTH_REDIRECT_URI: process.env.REACT_APP_LEGO_AUTH_REDIRECT_URI as string,
  VERSION: process.env.REACT_APP_VERSION as string,
};

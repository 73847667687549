import { Box, Container, Tabs, Text } from '@lego/klik-ui';
import { useNavigate } from 'react-router-dom';
import { FinishedMaterialRequestList } from '../../components/finished-material-request-list/finished-material-request-list';
import { PendingMaterialRequestList } from '../../components/pending-material-request-list/pending-material-request-list';
import { PlantForm } from '../../components/plant-form/plant-form';
import { SearchMaterial } from '../../components/search-material/search-material';
import { Spinner } from '../../components/spinner/spinner';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { useProfile } from '../../queries/profile';
import { RoutePaths } from '../../routes/app-router';
import { ErrorPage } from '../error-page/error-page';
import { searchParameterName } from '../search/search';

export const Home: React.FC = () => {
  const navigate = useNavigate();

  const {
    isLoading: isProfileLoading,
    data: profile,
    errorMessage: profileErrorMessage,
  } = useProfile();

  const showMaterialSearch = (value: string) => {
    navigate({
      pathname: RoutePaths.SearchPath,
      search: `${searchParameterName}=${value}`,
    });
  };

  if (isProfileLoading) return <Spinner isFullHeight isIndeterminate />;

  if (profileErrorMessage) return <ErrorPage errorMessage={profileErrorMessage} />;

  if (!profile?.Plant) {
    return (
      <Box marginTop="24px">
        <PlantForm />
      </Box>
    );
  }

  return (
    <>
      <Box backgroundColor="light-blue.700" paddingBottom="20px" paddingTop="100px">
        <Container maxWidth="container.xl">
          <Text as="h2" color="white" marginBottom="70px" textAlign="center" textStyle="h2">
            <FormattedMessage id="app.name" />
          </Text>
          <SearchMaterial
            onSelect={showMaterialSearch}
            searchDescriptionTextProps={{ color: 'white' }}
          />
        </Container>
      </Box>
      <Container marginTop="20px" maxWidth="container.xl">
        <Tabs>
          <Tabs.TabList>
            <Tabs.Tab>
              <FormattedMessage id="home.pendingRequests" />
            </Tabs.Tab>
            <Tabs.Tab>
              <FormattedMessage id="home.finishedRequests" />
            </Tabs.Tab>
          </Tabs.TabList>
          <Tabs.TabPanels>
            <Tabs.TabPanel>
              <PendingMaterialRequestList />
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              <FinishedMaterialRequestList />
            </Tabs.TabPanel>
          </Tabs.TabPanels>
        </Tabs>
      </Container>
    </>
  );
};

import { Text } from '@lego/klik-ui';
import { useFormikContext } from 'formik';
import { useCallback, useContext, useState } from 'react';
import * as Yup from 'yup';
import { IntlContext } from '../../i18n/intl/intl-context';
import { IMaterialRequest } from '../../models/material-request';
import { CanceledError } from '../../network/errors/canceled-error';
import { searchManufacturers } from '../../network/search-manufacturers';
import { doesManufacturerPartNumberExist } from '../../network/search-materials';
import { groupBy } from '../../utils/group-by';
import { nameof } from '../../utils/nameof';
import { IAsyncSelectOption, TLoadOptions } from '../search-dropdown/search-dropdown';
import { InputFormControl } from './form-controls/input-form-control';
import { SearchDropdownFormControl } from './form-controls/search-dropdown-form-control';

export const manufacturerFormValidationSchema = Yup.object<
  Partial<Record<keyof IMaterialRequest, Yup.AnySchema>>
>({
  ManufacturerPartNumber: Yup.string().when('Manufacturer', {
    is: (val?: string) => val !== undefined,
    then: (schema) =>
      schema
        .required('validation.required')
        .test(
          nameof<IMaterialRequest>('ManufacturerPartNumber'),
          'validation.wrongManufacturerPartNumber',
          async function (value) {
            const manufacturer = (this.parent as IMaterialRequest).Manufacturer;

            if (!value) return true;

            return !(await doesManufacturerPartNumberExist(manufacturer, value));
          },
        ),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const ManufacturerForm: React.FC = () => {
  const { values } = useFormikContext<IMaterialRequest>();
  const intl = useContext(IntlContext);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const onSearch = useCallback(async (searchText: string): Promise<IAsyncSelectOption[]> => {
    const results = await searchManufacturers(searchText, true);
    const resultsGrouped = groupBy(results, (i) => i.Name);

    return Array.from(resultsGrouped.entries())
      .sort(([_, av], [secondValue, bv]) => bv.length - av.length)
      .slice(0, 100)
      .map(([k, v]) => ({ value: k, label: k }));
  }, []);

  let timeoutId: NodeJS.Timeout;

  const onSearchDelayed: TLoadOptions = (inputValue, callback) => {
    clearTimeout(timeoutId);
    setErrorMessage(undefined);

    timeoutId = setTimeout(async () => {
      try {
        callback(await onSearch(inputValue));
      } catch (error) {
        if (!(error instanceof CanceledError)) {
          setErrorMessage(
            intl.formatMessage({
              id: 'error.errorOccurred',
            }),
          );

          callback([]);
        }
      }
    }, 500);
  };

  return (
    <>
      <SearchDropdownFormControl
        defaultValue={values.Manufacturer}
        errorMessage={errorMessage}
        fieldName="Manufacturer"
        isOptional
        label={intl.formatMessage({ id: 'manufacturerForm.manufacturerLabel' })}
        loadOptions={onSearchDelayed}
        placeholder={intl.formatMessage({
          id: 'manufacturerForm.findManufacturer',
        })}
        tooltip={
          <>
            <Text>
              {intl.formatMessage({
                id: 'manufacturerForm.manufacturerLabelTooltipPart1',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'manufacturerForm.manufacturerLabelTooltipPart2',
              })}
            </Text>
          </>
        }
      />
      {values.Manufacturer && (
        <InputFormControl
          fieldName="ManufacturerPartNumber"
          label={intl.formatMessage({
            id: 'manufacturerForm.manufacturerPartNumberLabel',
          })}
          tooltip={intl.formatMessage({
            id: 'manufacturerForm.manufacturerPartNumberLabelTooltip',
          })}
        />
      )}
    </>
  );
};

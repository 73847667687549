import { useMediaQuery } from '@chakra-ui/media-query';
import { Box, Divider, Grid, Stepper as KlikStepper } from '@lego/klik-ui';
import { PropsWithChildren, ReactNode, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { IntlContext } from '../../../i18n/intl/intl-context';
import { ScreenSize } from '../../../utils/screen-size';

export interface Step {
  title: 'Basic information' | 'Manufacturer' | 'Material' | 'Vendor';
  component: ReactNode;
  validationSchema: Yup.AnyObjectSchema;
}

interface StepperProps {
  steps: Step[];
  activeStep: number;
  completedSteps: number[];
  onValidationSchemaChange: (schema: Yup.AnyObjectSchema) => void;
}

export const Stepper: React.FC<PropsWithChildren<StepperProps>> = (props) => {
  const { steps, activeStep, completedSteps, onValidationSchemaChange, children } = props;

  const intl = useContext(IntlContext);

  const [isMobileView] = useMediaQuery(`(max-width: ${ScreenSize.MIN_WIDTH_TABLET})`);

  useEffect(() => {
    onValidationSchemaChange(steps[activeStep - 1].validationSchema);
  }, [activeStep, steps, onValidationSchemaChange]);

  const translatedTitle = (title: Step['title']) => {
    switch (title) {
      case 'Basic information':
        return intl.formatMessage({ id: 'stepper.basicInformation' });
      case 'Material':
        return intl.formatMessage({ id: 'stepper.material' });
      case 'Manufacturer':
        return intl.formatMessage({ id: 'stepper.manufacturer' });
      case 'Vendor':
        return intl.formatMessage({ id: 'stepper.vendor' });
      default:
        throw new Error(`Unknown title`);
    }
  };

  return (
    <>
      <KlikStepper
        {...{ activeStep, completedSteps }}
        orientation={isMobileView ? 'vertical' : 'horizontal'}
        readonlySteps={steps.map((_, index) => index + 1)}
      >
        {steps.map((step) => (
          <KlikStepper.Step key={step.title}>
            <KlikStepper.Label>{translatedTitle(step.title)}</KlikStepper.Label>
            {isMobileView && (
              <KlikStepper.Panel>
                <Box width="296px">
                  {step.component}
                  {step.title === 'Basic information' && children}
                </Box>
              </KlikStepper.Panel>
            )}
          </KlikStepper.Step>
        ))}
      </KlikStepper>
      {!isMobileView && (
        <Grid gap={6} marginTop="32px" templateColumns="50% auto 50%">
          {steps.map((step, index) => (
            <Box display={activeStep === index + 1 ? 'block' : 'none'} key={step.title}>
              {step.component}
            </Box>
          ))}
          <Divider orientation="vertical" />
          <Box width="400px">{children}</Box>
        </Grid>
      )}
    </>
  );
};

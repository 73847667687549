import { Text } from '@lego/klik-ui';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { IMaterialRequest } from '../../../models/material-request';
import { IFieldFormControl } from './form-control';

export const FormControlError: React.FC<Pick<IFieldFormControl, 'errorMessage' | 'fieldName'>> = (
  props,
) => {
  const { fieldName, errorMessage } = props;

  const { touched, errors, isValidating } = useFormikContext<IMaterialRequest>();

  const intl = useIntl();

  if (!errors[fieldName] && !errorMessage) return null;

  return (
    <>
      {!isValidating && !!touched[fieldName] && !!errors[fieldName] && (
        <Text color="error.500" fontSize="sm">
          {intl.formatMessage({ id: errors[fieldName] as string })}
        </Text>
      )}
      {!!errorMessage && (
        <Text color="error.500" fontSize="sm">
          {errorMessage}
        </Text>
      )}
    </>
  );
};

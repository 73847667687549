import { Dropdown } from '@lego/klik-ui';
import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { IntlContext } from '../../../i18n/intl/intl-context';
import { IMaterialRequest } from '../../../models/material-request';
import { IKeyValue } from '../../../utils/key-value';
import { IFieldFormControl } from './form-control';
import { FormControlContainer } from './form-control-container';
import { FormControlError } from './form-control-error';

interface ISelectFormControlProps extends IFieldFormControl {
  data: IKeyValue<string, string>[];
}

export const SelectFormControl: React.FC<ISelectFormControlProps> = (props) => {
  const { fieldName, errorMessage, data } = props;

  const intl = useContext(IntlContext);

  const {
    values,
    errors,
    touched,
    isValidating,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IMaterialRequest>();

  const formSetFieldValue = async (value: string) => {
    // setFieldValue uses setState internally which is async
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await setFieldValue(fieldName, value);
    setFieldTouched(fieldName);
    handleChange(fieldName);
  };

  return (
    <FormControlContainer
      isInvalid={(!!errors[fieldName] && !!touched[fieldName] && !isValidating) || !!errorMessage}
      {...props}
    >
      <Dropdown
        defaultValue={[values[fieldName] as string]}
        id={fieldName}
        listHeight="32vh"
        onBlur={handleBlur}
        onChange={async (value) => {
          return await formSetFieldValue(value);
        }}
        placeholder={intl.formatMessage({ id: 'selectInput.selectAnOption' })}
        size="sm"
      >
        {data.map((item) => (
          <Dropdown.Option key={item.key} value={item.key}>
            {item.value}
          </Dropdown.Option>
        ))}
      </Dropdown>
      <FormControlError {...props} />
    </FormControlContainer>
  );
};

import {
  CharacteristicNames,
  IMaterialClassification,
  IMaterialsSearch,
} from '../../network/sap-result-types';
import { serviceApi } from '../../network/service-api';
import {
  IMaterialSearchPayload,
  IMaterialSearchResultItemViewModel,
  IMaterialSearchResultViewModel,
} from '../models/material-search-result';

export const findCharacteristicValue = (
  classification: IMaterialClassification,
  characteristic: string,
): string | undefined => {
  const characteristicName = (Object.keys(classification) as (keyof typeof classification)[]).find(
    (k) => classification[k] === characteristic,
  ) as string;

  if (!characteristicName) return undefined;

  const characteristicNr = characteristicName.substring(
    characteristicName.length - 2,
    characteristicName.length,
  );

  const characteristicValueName = `CharacteristicValue${characteristicNr}`;

  return classification[characteristicValueName];
};

export const mapToMaterialSearchResultItemViewModel = (
  item: IMaterialsSearch,
): IMaterialSearchResultItemViewModel => {
  return {
    materialNumber: item.MaterialNumber,
    manufacturerPartNumber: item.General.MfrPartNumber,
    eanNumber: item.General.EAN,
    dimensions: item.General.SizeDimensions,
    description: item.Text.Description,
    industryStd: item.General.IndustryStandard,
    technicalDescription:
      findCharacteristicValue(item.Classification, CharacteristicNames.TechnicalDescription) ?? '',
  };
};

export const getMaterialsMapped = async (
  payload: IMaterialSearchPayload,
): Promise<IMaterialSearchResultViewModel> => {
  const data = await serviceApi.searchMaterials(payload.searchText, false, payload.paging);

  return {
    list: data.materials.map((sr) => mapToMaterialSearchResultItemViewModel(sr)),
    totalCount: data.totalCount,
  };
};

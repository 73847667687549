import { CustomUseToastOptions, UseToast } from '@lego/klik-ui';
import { IToast, Toast } from './toast';

export const showToast = (
  toastHookReference: ReturnType<UseToast>,
  position: CustomUseToastOptions['position'],
  toast: IToast,
  duration = 10000,
) => {
  const { title, description, variant } = toast;

  toastHookReference({
    position,
    duration,
    render: ({ onClose }) => <Toast {...{ title, description, variant, onClose }} />,
  });
};

import { Box, Divider, Flex, Image, Text } from '@lego/klik-ui';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { SearchDataResult } from '../../queries/models/material-search-result';
import { SearchResultsItem } from './search-results-item';

interface ISearchResultsProps {
  dataResult: SearchDataResult;
  searchText?: string;
  loadNextPage: () => void;
}

export const SearchResults: React.FC<ISearchResultsProps> = (props) => {
  const { dataResult, searchText, loadNextPage } = props;

  if (dataResult.result === undefined) return null;

  if (dataResult.materialsCount === 0) {
    return (
      <Flex alignItems="center" gap={10} justifyContent="center" margin="2em">
        <Flex alignItems="center" direction="column">
          <Text fontSize="xl" fontWeight="medium" marginBottom="1em">
            <FormattedMessage id="searchResults.noResultFor" values={{ searchText }} />
          </Text>
          <Text>
            <FormattedMessage id="searchResults.tryDifferentSearchTerm" />.
          </Text>
          <Text color="slate.400">
            <FormattedMessage id="searchResults.tryDifferentSearchTermDescription" />
          </Text>
        </Flex>
        <Image height="260px" src="/woman-magnifying-glass.svg" />
      </Flex>
    );
  } else {
    return (
      <>
        <Text color="slate.500" fontWeight="bold" marginTop="32px">
          <FormattedMessage
            id="searchResults.xResults"
            values={{
              numberOfResults: dataResult.materialsCount,
              totalCount: dataResult.totalCount,
            }}
          />
        </Text>
        <Divider />
        {dataResult.result.map((group, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={i}>
            {group.list.map((sr) => (
              <Box key={sr.materialNumber}>
                <Box margin="16px 0">
                  <SearchResultsItem item={sr} />
                </Box>
              </Box>
            ))}
          </React.Fragment>
        ))}
        {!dataResult.isLoading && dataResult.hasNextPage && <Waypoint onEnter={loadNextPage} />}
      </>
    );
  }
};

export const MATERIAL_TYPE_MRO_MATERIALS = 'Z103';

export const MATERIALS_SERVICE = 'YTEAMCENTER_SRV/Materials';
export const MATERIAL_DATA_SET_SERVICE = 'YPLM_MAT_SRV/MaterialDataSet';
export const MATERIAL_SEARCH_SET_SERVICE = 'YPLM_MAT_SRV/MaterialSearchSet';
export const MATERIAL_GROUP_SET_SERVICE = 'YTEAMCENTER_SRV/MaterialGroups';
export const NEW_MATERIAL_NUMBER_SET_SERVICE = 'YPLM_MAT_SRV/NewMaterialNumberSet';
export const BASE_UNIT_SET_SERVICE = 'YPLM_MAT_SRV/ShBaseUnitSet';
export const VENDORS_SERVICE = 'YTEAMCENTER_SRV/Vendors';
export const MANUFACTURERS_SERVICE = 'YTEAMCENTER_SRV/Vendors';

export interface ISAPResultResponse<TResultType> {
  d: TResultType;
}

export type ISAPResultSetResponse<TResultType> = ISAPResultResponse<{
  results: TResultType[];
  __count: string;
}>;

export interface IMaterialsSearch {
  MaterialNumber: string;
  Text: { Description: string };
  General: {
    MfrPartNumber: string;
    VendorMaterial: string;
    SizeDimensions: string;
    IndustryStandard: string;
    EAN: string;
  };
  Classification: IMaterialClassification;
}

export interface IMaterialsSearchResponse {
  materials: IMaterialsSearch[];
  totalCount: number;
}

export interface IVendorsSearch {
  Name: string;
  SupplierNumber: string;
}

export interface IManufacturersSearch {
  Name: string;
}

export interface IMaterialData {
  MaterialNumber: string;
  Manufacturer: string;
  ManufacturerPartNumber: string;
  VendorMaterial: string;
  MaterialDescription: string;
  Size: string;
  BaseUnit: string;
  MaterialGroup: string;
  IndustryStandard: string;
  Ean11Number: string;
  EanCategory: string;
}

export interface INewMaterialNumberData {
  MatnrMaterialNumber: string;
}

export interface IMaterialSearch {
  MaterialNumber: string;
  MaterialDescr: string;
  Manufacturer: string;
  ManufacturerPartNumber: string;
}

export interface IMaterialGroup {
  MaterialGroupId: string;
  Text: string;
  Description: string;
}

export interface IBaseUnit {
  UnitId: string;
  UnitDescr: string;
}

export interface ICreateMaterialData {
  MaterialNumber: string;
  General: {
    MaterialGroup: string;
    MaterialType: string;
    IndustrySector: string;
    BaseUnit: string;
    OldMaterialNumber: string;
    IndustryStandard: string;
    EAN: string;
    EanCategory: string;
    SizeDimensions: string;
    MfrPartNumber: string;
  };
  Text: {
    Description: string;
  };
  Plant: {
    Plant: string;
    MRPType: string;
    MRPController: string;
    SchedMarginKey: string;
    LotSize: string;
    AbcIndicator: string;
  };
  Valuation: {
    ValuationClass: string;
    // TODO Uncomment code below when StandardPrice is provided
    //StandardPrice: string;
  };
  Classification: {
    CharacteristicName01: string;
    CharacteristicValue01: string;
    CharacteristicName02: string;
    CharacteristicValue02: string;
  };
  InfoRecord?: {
    VendorNumber: string;
    VendorMaterialNumber: string;
    PlannedDeliveryDays: string;
    Price: string;
  };
  TC: {
    TCObjectType: string;
  };
}

export const CharacteristicNames = {
  TechnicalDescription: 'EM_MRO_MAT_TECH_DESCR',
  Manufacturer: 'EM_MRO_MANUFAC_NAME',
};

export interface IMaterialClassification {
  [key: string]: string;
  CharacteristicName01: string;
  CharacteristicValue01: string;
  CharacteristicName02: string;
  CharacteristicValue02: string;
  CharacteristicName03: string;
  CharacteristicValue03: string;
  CharacteristicName04: string;
  CharacteristicValue04: string;
  CharacteristicName05: string;
  CharacteristicValue05: string;
  CharacteristicName06: string;
  CharacteristicValue06: string;
  CharacteristicName07: string;
  CharacteristicValue07: string;
  CharacteristicName08: string;
  CharacteristicValue08: string;
  CharacteristicName09: string;
  CharacteristicValue09: string;
  CharacteristicName10: string;
  CharacteristicValue10: string;
  CharacteristicName11: string;
  CharacteristicValue11: string;
  CharacteristicName12: string;
  CharacteristicValue12: string;
  CharacteristicName13: string;
  CharacteristicValue13: string;
  CharacteristicName14: string;
  CharacteristicValue14: string;
  CharacteristicName15: string;
  CharacteristicValue15: string;
  CharacteristicName16: string;
  CharacteristicValue16: string;
  CharacteristicName17: string;
  CharacteristicValue17: string;
  CharacteristicName18: string;
  CharacteristicValue18: string;
  CharacteristicName19: string;
  CharacteristicValue19: string;
  CharacteristicName20: string;
  CharacteristicValue20: string;
  CharacteristicName21: string;
  CharacteristicValue21: string;
  CharacteristicName22: string;
  CharacteristicValue22: string;
  CharacteristicName23: string;
  CharacteristicValue23: string;
  CharacteristicName24: string;
  CharacteristicValue24: string;
  CharacteristicName25: string;
  CharacteristicValue25: string;
}

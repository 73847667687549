import { CircularProgress, Flex } from '@lego/klik-ui';

interface ISpinnerProps {
  isIndeterminate?: boolean;
  text?: string;
  isFullHeight?: boolean;
}

export const Spinner: React.FC<ISpinnerProps> = (props) => {
  const { isIndeterminate, text, isFullHeight } = props;

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      height={isFullHeight ? '100vh' : undefined}
      justifyContent="center"
    >
      <CircularProgress isIndeterminate={isIndeterminate} />
      {text && <p>{text}</p>}
    </Flex>
  );
};

import { IProfileDTO } from '@material-creation/common';
import { useMutation, useQueryClient } from 'react-query';
import { serviceApi } from '../../network/service-api';
import { QueryKeys } from '../query-keys';

const key = [QueryKeys.Profile];

const mutationFn = (profile: IProfileDTO) => {
  return serviceApi.updateProfile(profile);
};

export const useUpdateProfile = (onError: (errorMessage: string) => void) => {
  const queryClient = useQueryClient();

  return useMutation((profile: IProfileDTO) => mutationFn(profile), {
    onSuccess: () => {
      void queryClient.invalidateQueries(key);
    },
    onError: (error) => {
      const errorMessage = error ? (error as Error).message : 'An unexpected error occurred';

      onError(errorMessage);
    },
  });
};

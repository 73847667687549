import type { ReactNode } from 'react';
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message';
import translations_en from '../assets/en-US.json';

export type IntlMessageKeys = keyof typeof translations_en;

type FormattedMessageProps = ReactIntlFormattedMessageProps<Record<string, ReactNode>> & {
  id?: IntlMessageKeys;
};

export const FormattedMessage = ({ id, ...rest }: FormattedMessageProps) => {
  return <ReactIntlFormattedMessage id={id} {...rest} />;
};

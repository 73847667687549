import { useMutation, useQueryClient } from 'react-query';
import { IMaterialRequest } from '../../models/material-request';
import { createMaterialRequest } from '../../network/create-material-request';
import { QueryKeys } from '../query-keys';

const pendingMaterialRequestsKey = [QueryKeys.PendingMaterialRequests];
const finishedMaterialRequestsKey = [QueryKeys.FinishedMaterialRequests];

const mutationFn = (request: IMaterialRequest) => {
  return createMaterialRequest(request);
};

export const useMutateCreateMaterialRequest = (
  onSuccess: () => void,
  onError: (errorMessage?: string) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation((request: IMaterialRequest) => mutationFn(request), {
    onSuccess: () => {
      void queryClient.invalidateQueries(pendingMaterialRequestsKey);
      void queryClient.invalidateQueries(finishedMaterialRequestsKey);
      onSuccess();
    },
    onError: (error) => {
      const errorMessage = error ? (error as Error).message : undefined;
      onError(errorMessage);
    },
  });
};

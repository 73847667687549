import { useMediaQuery } from '@chakra-ui/media-query';
import { Box, Button, Flex, Input } from '@lego/klik-ui';
import { Text } from '@lego/klik-ui-text';
import { IProfileDTO } from '@material-creation/common';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { useUpdateProfile } from '../../queries/mutations/update-profile';
import { useProfile } from '../../queries/profile';
import { browserLanguage } from '../../utils/languages';
import { ScreenSize } from '../../utils/screen-size';

export const PlantForm: React.FC = () => {
  const intl = useIntl();
  const { data: profile } = useProfile();

  const [initialValues] = useState<IProfileDTO>({
    Plant: profile?.Plant ?? '',
    Language: profile?.Language ?? browserLanguage,
  });

  const [errorMessageOnUpdateProfile, setErrorMessageOnUpdateProfile] = useState<
    string | undefined
  >(undefined);

  const { mutate: updateProfile, isLoading } = useUpdateProfile(setErrorMessageOnUpdateProfile);

  const [isMobileView] = useMediaQuery(`(max-width: ${ScreenSize.MIN_WIDTH_TABLET})`);

  const validationSchema = Yup.object<Partial<Record<keyof IProfileDTO, Yup.AnySchema>>>({
    Plant: Yup.string().required('validation.required'),
  });

  const onFormSubmit = (newProfile: IProfileDTO) => {
    setErrorMessageOnUpdateProfile(undefined);
    updateProfile(newProfile);
  };

  return (
    <>
      <Text as="h2" textAlign="center" textStyle="h2">
        {!profile?.Plant && <FormattedMessage id="plantForm.title" />}
        {profile?.Plant && <FormattedMessage id="plantForm.editTitle" />}
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <Flex justifyContent="center" marginTop="24px">
                <Box>
                  <Input
                    isInvalid={
                      (!!formProps.errors.Plant && formProps.touched.Plant) ||
                      errorMessageOnUpdateProfile !== undefined
                    }
                    name="Plant"
                    onBlur={formProps.handleBlur}
                    onChange={formProps.handleChange}
                    placeholder={intl.formatMessage({
                      id: 'plantForm.enterYourPlant',
                    })}
                    value={formProps.values.Plant}
                    width={isMobileView ? '' : 'md'}
                  />
                  {formProps.touched.Plant && formProps.errors.Plant && (
                    <Text color="error.500" fontSize="sm">
                      {intl.formatMessage({
                        id: formProps.errors.Plant as string,
                      })}
                    </Text>
                  )}
                  {errorMessageOnUpdateProfile && (
                    <Text color="error.500" fontSize="sm" textAlign="center">
                      {errorMessageOnUpdateProfile}
                    </Text>
                  )}
                </Box>
                <Button height="fit-content" isLoading={isLoading} marginLeft="16px" type="submit">
                  <FormattedMessage id="plantForm.save" />
                </Button>
              </Flex>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { getEnv } from '../env';

export const msalConfig: Configuration = {
  auth: {
    clientId: getEnv.LEGO_AUTH_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/legogroup.onmicrosoft.com',
    redirectUri: getEnv.LEGO_AUTH_REDIRECT_URI,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

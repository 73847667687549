import { createContext, PropsWithChildren, useMemo } from 'react';
import type { IntlFormatters } from 'react-intl';
import { useIntl } from 'react-intl';
import translations_en from '../assets/en-US.json';

type IntlMessageKeys = keyof typeof translations_en;

type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>;

export interface IIntlContext {
  formatMessage: (
    descriptor: FormatMessageArgs[0] & {
      id?: IntlMessageKeys;
    },
    values?: FormatMessageArgs[1],
    options?: FormatMessageArgs[2],
  ) => string;
}

export const IntlContext = createContext<IIntlContext>({
  formatMessage: () => '',
} as IIntlContext);

export const IntlContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { formatMessage, ...rest } = useIntl();

  const value: IIntlContext = useMemo(() => {
    const typedFormatMessage = (
      descriptor: FormatMessageArgs[0] & {
        id?: IntlMessageKeys;
      },
      values?: FormatMessageArgs[1],
      options?: FormatMessageArgs[2],
    ) => {
      return formatMessage(descriptor, values, options) as string;
    };

    return {
      ...rest,
      formatMessage: typedFormatMessage,
    };
  }, [formatMessage, rest]);

  return <IntlContext.Provider value={value}>{children}</IntlContext.Provider>;
};

import { MaterialRequestStatus } from '@material-creation/common';
import { useMutation, useQueryClient } from 'react-query';
import { IMaterialRequest } from '../../models/material-request';
import { createMaterial } from '../../network/create-material';
import { updateMaterialRequest } from '../../network/update-material-request';
import { msalInstance } from '../../security/msal';
import { QueryKeys } from '../query-keys';

const pendingMaterialRequestsKey = [QueryKeys.PendingMaterialRequests];
const finishedMaterialRequestsKey = [QueryKeys.FinishedMaterialRequests];

const mutationFn = async (request: IMaterialRequest) => {
  const materialNumber = await createMaterial(request);

  if (!materialNumber) {
    throw new Error('Material number not created');
  }

  const updatedRequest: IMaterialRequest = {
    ...request,
    MaterialNumber: materialNumber,
    RequestStatus: MaterialRequestStatus.Approved,
    StatusChangedByEmail: msalInstance.getActiveAccount()!.username,
    StatusChangedByName: msalInstance.getActiveAccount()!.name ?? 'N/A',
  };

  return updateMaterialRequest(updatedRequest);
};

export const useApproveMaterialRequest = (
  onSuccess: (requestId?: string) => void,
  onError: (errorMessage?: string) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation((request: IMaterialRequest) => mutationFn(request), {
    onSuccess: (response) => {
      void queryClient.invalidateQueries(pendingMaterialRequestsKey);
      void queryClient.invalidateQueries(finishedMaterialRequestsKey);
      onSuccess(response.Id);
    },
    onError: (error) => {
      const errorMessage = error ? (error as Error).message : undefined;
      onError(errorMessage);
    },
  });
};

import * as sapApi from './api/sap-api';
import { buildSapApiUri } from './api/sap-api';
import {
  IMaterialGroup,
  ISAPResultSetResponse,
  MATERIAL_GROUP_SET_SERVICE,
} from './sap-result-types';

export const getMaterialGroups = async (): Promise<IMaterialGroup[]> => {
  const selectString = 'MaterialGroupId,Text,Description';

  const filterString =
    "MaterialGroupId ne '0320' and MaterialGroupId ne '0321' and MaterialGroupId ne '0322' and MaterialGroupId ne '0323' and MaterialGroupId ne '0324' and MaterialGroupId ne '0325'";

  const uri = buildSapApiUri(MATERIAL_GROUP_SET_SERVICE, filterString, selectString);

  const response = await sapApi.get<ISAPResultSetResponse<IMaterialGroup>>(uri);

  return response.d.results;
};

import { Input } from '@lego/klik-ui';
import { useFormikContext } from 'formik';
import { IMaterialRequest } from '../../../models/material-request';
import { IFieldFormControl } from './form-control';
import { FormControlContainer } from './form-control-container';
import { FormControlError } from './form-control-error';

export const InputFormControl: React.FC<IFieldFormControl> = (props) => {
  const { fieldName, errorMessage } = props;

  const { values, errors, touched, isValidating, handleChange, handleBlur } =
    useFormikContext<IMaterialRequest>();

  return (
    <FormControlContainer {...props}>
      <Input
        id={fieldName}
        isInvalid={(!!errors[fieldName] && !!touched[fieldName] && !isValidating) || !!errorMessage}
        name={fieldName}
        onBlur={handleBlur}
        onChange={handleChange}
        size="sm"
        value={values[fieldName] as string}
      />
      <FormControlError {...props} />
    </FormControlContainer>
  );
};

import { CloseButton } from '@lego/klik-ui-close-button';
import { InlineNotification } from '@lego/klik-ui-inline-notification';
import React from 'react';

export interface IToast {
  title: string;
  description: string;
  variant?: 'error' | 'info' | 'plain' | 'success' | 'warning';
}

interface IToastProps extends IToast {
  onClose: () => void;
}

export const Toast: React.FC<IToastProps> = (props) => {
  const { title, description, onClose, variant = 'plain' } = props;

  return (
    <InlineNotification variant={variant}>
      <InlineNotification.Content alignItems="flex-start" flexDirection="column">
        <InlineNotification.Title>{title}</InlineNotification.Title>
        <InlineNotification.Description>{description}</InlineNotification.Description>
      </InlineNotification.Content>
      <CloseButton aria-label="" onClick={onClose} />
    </InlineNotification>
  );
};

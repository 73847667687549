import * as sapApi from './api/sap-api';
import { buildSapApiUri } from './api/sap-api';
import { BASE_UNIT_SET_SERVICE, IBaseUnit, ISAPResultSetResponse } from './sap-result-types';

export const getUnitsOfMeasure = async (): Promise<IBaseUnit[]> => {
  const uri = buildSapApiUri(BASE_UNIT_SET_SERVICE);
  const response = await sapApi.get<ISAPResultSetResponse<IBaseUnit>>(uri);

  return response.d.results;
};

import { nameof } from '../utils/nameof';
import * as sapApi from './api/sap-api';
import { buildSapApiUri } from './api/sap-api';
import { NotFoundError } from './errors/not-found-error';
import {
  IMaterialData,
  ISAPResultSetResponse,
  MATERIAL_DATA_SET_SERVICE,
} from './sap-result-types';

export const getMaterial = async (materialNumber: string): Promise<IMaterialData> => {
  const materialNumberAttribute = nameof<IMaterialData>('MaterialNumber');

  const manufacturerPartNumberAttribute = nameof<IMaterialData>('ManufacturerPartNumber');

  const vendorMaterialAttribute = nameof<IMaterialData>('VendorMaterial');

  const materialDescriptionAttribute = nameof<IMaterialData>('MaterialDescription');

  const sizeAttribute = nameof<IMaterialData>('Size');
  const materialGroupAttribute = nameof<IMaterialData>('MaterialGroup');
  const unitOfMeasureAttribute = nameof<IMaterialData>('BaseUnit');
  const industryStandardAttribute = nameof<IMaterialData>('IndustryStandard');

  const filterString = `MaterialNumber eq '${materialNumber}'`;

  const selectString = `${materialNumberAttribute},${manufacturerPartNumberAttribute},${vendorMaterialAttribute},${materialDescriptionAttribute},${sizeAttribute},${materialGroupAttribute},${industryStandardAttribute},${unitOfMeasureAttribute}`;

  const uri = buildSapApiUri(MATERIAL_DATA_SET_SERVICE, filterString, selectString);

  const response = await sapApi.get<ISAPResultSetResponse<IMaterialData>>(uri);

  if (response.d.results.length === 0) throw new NotFoundError();

  return response.d.results[0];
};

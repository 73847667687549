import { useMediaQuery } from '@chakra-ui/media-query';
import { Box } from '@lego/klik-ui';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ScreenSize } from '../../utils/screen-size';
import { Breadcrumb } from '../breadcrumb/breadcrumb';
import { Footer, FOOTER_HEIGHT } from '../footer/footer';
import { NavBar } from '../navbar/navbar';

export const Layout: React.FC = () => {
  const [isNotMobileView] = useMediaQuery(`(min-height: ${ScreenSize.MAX_HEIGHT_MOBILE})`);

  return (
    <Box paddingBottom={isNotMobileView ? FOOTER_HEIGHT : 0}>
      <NavBar />
      <Breadcrumb />
      <Outlet />
      {isNotMobileView && <Footer />}
    </Box>
  );
};

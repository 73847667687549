import { Link, useToast } from '@lego/klik-ui';
import { showToast } from '../toast/show-toast';
import { useFileDownload } from './file-download';

interface FileDownloadLinkProps {
  fileName: string;
  apiDefinition: () => Promise<Blob>;
}

export const FileDownloadLink: React.FC<FileDownloadLinkProps> = (props) => {
  const toast = useToast();

  const { fileName, apiDefinition } = props;

  const onErrorDownloadingFile = (errorMessage?: string) => {
    showToast(toast, 'top', {
      title: 'Error downloading file',
      description: errorMessage ?? 'Unknown error',
      variant: 'error',
    });
  };

  const { ref, download } = useFileDownload({
    apiDefinition,
    onError: onErrorDownloadingFile,
  });

  return (
    <>
      <Link download={fileName} hidden ref={ref} />
      <Link
        onClick={() => {
          void download();
        }}
      >
        {fileName}
      </Link>
    </>
  );
};

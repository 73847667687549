import { useMediaQuery } from '@chakra-ui/media-query';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Link,
  Text,
  useDisclosure,
  useToast,
} from '@lego/klik-ui';
import { MaterialRequestStatus } from '@material-creation/common';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialRequestRole } from '../../enums/material-request-role';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { IMaterialRequest } from '../../models/material-request';
import { requestIdParamName } from '../../pages/material-request/material-request';
import { RoutePaths } from '../../routes/app-router';
import { ScreenSize } from '../../utils/screen-size';
import { userHasRole } from '../../utils/user-has-role';
import { Modal } from '../modal/modal';
import { ProtectedImage } from '../protected-image/protected-image';
import { showToast } from '../toast/show-toast';

interface IMaterialRequestListItemProps {
  request: IMaterialRequest;
}

export const MaterialRequestListItem: React.FC<IMaterialRequestListItemProps> = (props) => {
  const { request } = props;

  const navigate = useNavigate();
  const toast = useToast();

  const [isMobileView] = useMediaQuery(`(max-width: ${ScreenSize.MIN_WIDTH_TABLET})`);

  const { isOpen: isSeeMoreOpen, onOpen: onSeeMoreOpen, onClose: onSeeMoreClose } = useDisclosure();

  const onFinishClick = (requestId?: string) => {
    if (requestId) {
      navigate({
        pathname: `/${RoutePaths.RequestPath}`,
        search: `${requestIdParamName}=${requestId}`,
      });
    } else {
      showToast(toast, 'top-right', {
        title: 'Material Id',
        description: 'Material id not found',
        variant: 'error',
      });
    }
  };

  const statusColorTheme = useMemo(() => {
    switch (request.RequestStatus) {
      case MaterialRequestStatus.Approved:
        return 'success.500';
      case MaterialRequestStatus.Rejected:
        return 'error.500';
      default:
        return 'light-blue.400';
    }
  }, [request.RequestStatus]);

  const seeMoreLink = useMemo(
    () => (
      <>
        <Link fontSize="small" onClick={onSeeMoreOpen}>
          <FormattedMessage id="materialRequestListItem.seeMore" />
        </Link>
        <Modal
          isOpen={isSeeMoreOpen}
          modalTitle={request.Description}
          onClose={onSeeMoreClose}
          size={isMobileView ? 'sm' : 'md'}
        >
          <Grid templateColumns="auto auto">
            {isMobileView && (
              <>
                <Text fontSize="small">
                  <FormattedMessage id="materialRequestListItem.materialNumber" />:
                </Text>
                <Text fontSize="small" justifySelf="end">
                  {request.MaterialNumber}
                </Text>
              </>
            )}
            <Text fontSize="small">
              <FormattedMessage id="materialRequestListItem.manufacturerPartNr" />:
            </Text>
            <Text fontSize="small" justifySelf="end">
              {request.ManufacturerPartNumber}
            </Text>
            <Text fontSize="small">
              <FormattedMessage id="materialRequestListItem.vendorMaterialNr" />:
            </Text>
            <Text fontSize="small" justifySelf="end">
              {request.VendorMaterialNumber}
            </Text>
            <Text fontSize="small">
              <FormattedMessage id="materialRequestListItem.sizeDimension" />:
            </Text>
            <Text fontSize="small" justifySelf="end">
              {request.Size}
            </Text>
            <Text fontSize="small">
              <FormattedMessage id="materialRequestListItem.industryStd" />:
            </Text>
            <Text fontSize="small" justifySelf="end">
              {`${request.IndustryStandard} ${request.IndustryStandardNumber}`}
            </Text>
            <Text fontSize="small">
              <FormattedMessage id="materialRequestListItem.technicalDescription" />:
            </Text>
            <Text fontSize="small" justifySelf="end">
              {request.TechnicalDescription}
            </Text>
          </Grid>
        </Modal>
      </>
    ),
    [isMobileView, isSeeMoreOpen, onSeeMoreClose, onSeeMoreOpen, request],
  );

  return (
    <Flex margin="12px 0" position="relative" width="100%">
      <ProtectedImage
        _hover={{ transform: 'scale(1.2)' }}
        fileId={request.MaterialPictureFileId}
        maxWidth="128px"
        objectFit="contain"
        transition="transform 0.2s"
      />
      {isMobileView && request.RequestStatus !== MaterialRequestStatus.Pending && (
        <Text
          bgColor={statusColorTheme}
          borderRadius="6px"
          color="white"
          left="4px"
          position="absolute"
          textAlign="center"
          top="4px"
          width="120px"
        >
          {MaterialRequestStatus[request.RequestStatus]}
        </Text>
      )}
      <Flex flexDirection="column" marginLeft="18px" width="100%">
        <Text fontWeight="bold">{request.Description}</Text>
        <Flex flexDirection={isMobileView ? 'column' : 'row'} height="100%">
          <Flex
            flexDirection={isMobileView ? 'row' : 'column'}
            justifyContent={isMobileView ? 'space-between' : 'normal'}
            width={isMobileView ? 'auto' : '20%'}
          >
            <Text fontSize="small">
              <FormattedMessage id="materialRequestListItem.requested" />:
            </Text>
            <Text fontSize={isMobileView ? 'small' : 'medium'} fontWeight="bold">
              {moment(request.CreatedAt).format('YYYY-MM-DD HH:mm')}
            </Text>
          </Flex>
          {userHasRole(MaterialRequestRole.Approver) && (
            <Flex
              flexDirection={isMobileView ? 'row' : 'column'}
              justifyContent={isMobileView ? 'space-between' : 'normal'}
              width={isMobileView ? 'auto' : '20%'}
            >
              <Text fontSize="small">
                <FormattedMessage id="materialRequestListItem.requester" />:
              </Text>
              <Text fontSize={isMobileView ? 'small' : 'medium'} fontWeight="bold">
                {request.RequesterName}
              </Text>
            </Flex>
          )}
          {!isMobileView && request.RequestStatus !== MaterialRequestStatus.Pending && (
            <Box marginTop="24px" width="20%">
              <Text
                bgColor={statusColorTheme}
                borderRadius="6px"
                color="white"
                height="min-content"
                maxWidth="120px"
                textAlign="center"
              >
                {MaterialRequestStatus[request.RequestStatus]}
              </Text>
            </Box>
          )}
          {request.RequestStatus === MaterialRequestStatus.Approved && (
            <Flex
              flexDirection={isMobileView ? 'row' : 'column'}
              justifyContent={isMobileView ? 'space-between' : 'normal'}
              width={isMobileView ? 'auto' : '20%'}
            >
              <Text fontSize="small">
                <FormattedMessage id="materialRequestListItem.materialNumber" />:
              </Text>
              <Text fontSize={isMobileView ? 'small' : 'medium'} fontWeight="bold">
                {request.MaterialNumber}
              </Text>
            </Flex>
          )}
          {request.RequestStatus === MaterialRequestStatus.Rejected && (
            <Flex
              flexDirection={isMobileView ? 'row' : 'column'}
              justifyContent={isMobileView ? 'space-between' : 'normal'}
              width={isMobileView ? 'auto' : '20%'}
            >
              <Text fontSize="small">
                <FormattedMessage id="materialRequestListItem.reasonOfRejection" />:
              </Text>
              <Text fontSize={isMobileView ? 'small' : 'medium'} fontWeight="bold">
                {/*eslint-disable-next-line @typescript-eslint/no-unnecessary-condition*/}
                {request.RejectionMessage ?? 'N/A'}
              </Text>
            </Flex>
          )}
          {request.RequestStatus === MaterialRequestStatus.Pending &&
            userHasRole(MaterialRequestRole.Approver) && (
              <Box width={isMobileView ? 'auto' : '20%'}>
                <Button
                  height="min-content"
                  marginTop={isMobileView ? 'auto' : '20px'}
                  onClick={() => onFinishClick(request.Id)}
                  size="sm"
                  width={isMobileView ? '100%' : 'min-content'}
                >
                  <FormattedMessage id="materialRequestListItem.finishRequest" />
                </Button>
              </Box>
            )}
          {request.RequestStatus === MaterialRequestStatus.Approved && (
            <Box textAlign="center">
              {isMobileView && <Divider />}
              {seeMoreLink}
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

import { Text } from '@lego/klik-ui';
import { useContext } from 'react';
import * as Yup from 'yup';
import { IntlContext } from '../../i18n/intl/intl-context';
import { IMaterialRequest } from '../../models/material-request';
import { useUnitsOfMeasure } from '../../queries/units-of-measure';
import { IKeyValue } from '../../utils/key-value';
import { nameof } from '../../utils/nameof';
import { FormControlGroup } from './form-controls/form-control-group';
import { InputFormControl } from './form-controls/input-form-control';
import { SelectFormControl } from './form-controls/select-form-control';
import { SelectInputSplitFormControl } from './form-controls/select-input-split-form-control';

export const materialFormValidationSchema = Yup.object<
  Partial<Record<keyof IMaterialRequest, Yup.AnySchema>>
>({
  UnitOfMeasure: Yup.string().required('validation.required'),
  Size: Yup.string().test(
    nameof<IMaterialRequest>('Size'),
    'validation.required',
    function (value: string | undefined) {
      return (
        value !== undefined ||
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (this.parent as IMaterialRequest).TechnicalDescription !== undefined
      );
    },
  ),
  TechnicalDescription: Yup.string()
    .max(30, 'validation.technicalDescriptionMax30Chars')
    .test(
      nameof<IMaterialRequest>('TechnicalDescription'),
      'validation.required',
      function (value: string | undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return value !== undefined || (this.parent as IMaterialRequest).Size !== undefined;
      },
    ),
  ABCRating: Yup.string().required('validation.required'),
});

export const MaterialForm: React.FC = () => {
  const { data: unitsOfMeasure, errorMessage: unitsOfMeasureError } = useUnitsOfMeasure();

  const intl = useContext(IntlContext);

  const industryStandards = ['ISO', 'DIN', 'ASTM', 'DS'];

  const abcRatingValues: IKeyValue<string, string>[] = [
    { key: 'A', value: 'A - Important (Major)' },
    { key: 'B', value: 'B - Less important (Significant)' },
    { key: 'C', value: 'C - Relatively unimportant (Important)' },
  ];

  return (
    <>
      <SelectFormControl
        data={
          unitsOfMeasure?.map((unit) => ({
            key: unit.id,
            value: unit.description,
          })) ?? []
        }
        errorMessage={unitsOfMeasureError}
        fieldName="UnitOfMeasure"
        label={intl.formatMessage({
          id: 'materialForm.basicUnitOfMeasureLabel',
        })}
        tooltip={
          <>
            <Text>
              {intl.formatMessage({
                id: 'materialForm.basicUnitOfMeasureLabelTooltipPart1',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'materialForm.basicUnitOfMeasureLabelTooltipPart2',
              })}
            </Text>
          </>
        }
      />
      <FormControlGroup title={intl.formatMessage({ id: 'materialForm.fillOutOneOfFields' })}>
        <InputFormControl
          fieldName="Size"
          label={intl.formatMessage({ id: 'materialForm.sizeDimensionLabel' })}
          tooltip={
            <>
              <Text>
                {intl.formatMessage({
                  id: 'materialForm.sizeDimensionLabelTooltipPart1',
                })}
              </Text>
              <Text>
                {intl.formatMessage({
                  id: 'materialForm.sizeDimensionLabelTooltipPart2',
                })}
              </Text>
              <Text>
                {intl.formatMessage({
                  id: 'materialForm.sizeDimensionLabelTooltipPart3',
                })}
              </Text>
            </>
          }
        />
        <InputFormControl
          fieldName="TechnicalDescription"
          label={intl.formatMessage({
            id: 'materialForm.materialTechnicalDescriptionLabel',
          })}
          tooltip={
            <>
              <Text>
                {intl.formatMessage({
                  id: 'materialForm.materialTechnicalDescriptionLabelTooltipPart1',
                })}
              </Text>
              <Text>
                {intl.formatMessage({
                  id: 'materialForm.materialTechnicalDescriptionLabelTooltipPart2',
                })}
              </Text>
            </>
          }
        />
      </FormControlGroup>
      <SelectInputSplitFormControl
        data={industryStandards.map((standard) => ({
          key: standard,
          value: standard,
        }))}
        inputFieldName="IndustryStandardNumber"
        isOptional
        label={intl.formatMessage({ id: 'materialForm.industryStdLabel' })}
        selectFieldName="IndustryStandard"
      />
      <SelectFormControl
        data={abcRatingValues}
        fieldName="ABCRating"
        label={intl.formatMessage({ id: 'materialForm.abcRatingLabel' })}
        tooltip={
          <>
            <Text>
              {intl.formatMessage({
                id: 'materialForm.abcRatingLabelTooltipPart1',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'materialForm.abcRatingLabelTooltipPart2',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'materialForm.abcRatingLabelTooltipPart3',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'materialForm.abcRatingLabelTooltipPart4',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'materialForm.abcRatingLabelTooltipPart5',
              })}
            </Text>
          </>
        }
      />
    </>
  );
};

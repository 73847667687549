import { IMaterialRequest } from '../models/material-request';
import { mapToMaterialRequestDTO } from '../utils/material-request-mapper';
import * as materialCreationApi from './api/material-creation-api';
import { ICreatedMaterialRequestResponse } from './material-creation-api-result-types';

export const createMaterialRequest = async (
  materialRequest: IMaterialRequest,
): Promise<ICreatedMaterialRequestResponse> => {
  const uri = `mat-requests`;

  const materialRequestDTO = mapToMaterialRequestDTO(materialRequest);

  return materialCreationApi.post<typeof materialRequestDTO, ICreatedMaterialRequestResponse>(
    uri,
    materialRequestDTO,
  );
};

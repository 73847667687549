import React, { PropsWithChildren } from 'react';
import { ErrorPage } from '../error-page/error-page';

interface IErrorPageWrapperProps {
  errorMessage?: string;
  clearErrorMessage?: () => void;
}

export const ErrorPageWrapper: React.FC<PropsWithChildren<IErrorPageWrapperProps>> = (props) => {
  const { errorMessage, clearErrorMessage, children } = props;

  if (errorMessage) {
    return <ErrorPage clearErrorMessage={clearErrorMessage} errorMessage={errorMessage} />;
  } else {
    return <>{children}</>;
  }
};

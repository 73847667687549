import { useInfiniteQuery, useQueryClient } from 'react-query';
import { getMaterialsMapped } from './mappers/material-search-result';
import { QueryKeys } from './query-keys';

const PAGE_SIZE = 10;

const queryFn = (searchText?: string, pageParam?: any) => {
  if (searchText === undefined) throw new Error('Invalid searchText');

  const paging = {
    skip: typeof pageParam !== 'number' ? 0 : pageParam,
    top: PAGE_SIZE,
    orderby: '',
  };

  return getMaterialsMapped({
    searchText,
    paging,
  });
};

export const useMaterialSearch = (searchText?: string) => {
  const key = [QueryKeys.MaterialSearch, searchText];
  const queryClient = useQueryClient();

  const query = useInfiniteQuery(
    key,
    ({ queryKey, pageParam }) => {
      return queryFn(queryKey[1], pageParam);
    },
    {
      enabled: searchText !== undefined,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const totalResults = allPages.map((v) => v.list).flat().length;

        return lastPage.totalCount > totalResults ? totalResults : undefined;
      },
    },
  );

  return {
    ...query,
    errorMessage: query.error ? (query.error as Error).message : undefined,
    reset: () => {
      queryClient.setQueryData(key, undefined);
    },
  };
};

import { IMaterialRequest } from '../models/material-request';
import * as sapApi from './api/sap-api';
import { buildSapApiUri } from './api/sap-api';
import {
  CharacteristicNames,
  ICreateMaterialData,
  INewMaterialNumberData,
  ISAPResultResponse,
  MATERIALS_SERVICE,
  MATERIAL_TYPE_MRO_MATERIALS,
  NEW_MATERIAL_NUMBER_SET_SERVICE,
} from './sap-result-types';

const mapMaterialRequestToMaterial = (
  request: IMaterialRequest,
  materialNumber: string,
): ICreateMaterialData => {
  let materialData: ICreateMaterialData = {
    MaterialNumber: materialNumber,
    General: {
      MaterialGroup: request.MaterialGroupId,
      MaterialType: MATERIAL_TYPE_MRO_MATERIALS,
      IndustrySector: 'T',
      BaseUnit: request.UnitOfMeasure,
      OldMaterialNumber: request.OldMaterialNumber,
      SizeDimensions: request.Size,
      IndustryStandard: request.IndustryStandard + request.IndustryStandardNumber,
      EAN: request.EanUpc,
      EanCategory: request.EanCategory,
      MfrPartNumber: request.ManufacturerPartNumber,
    },
    Text: {
      Description: request.Description,
    },
    Plant: {
      Plant: '500',
      MRPType: 'ND',
      MRPController: '199',
      SchedMarginKey: '000',
      LotSize: 'EX',
      AbcIndicator: request.ABCRating,
    },
    Valuation: {
      ValuationClass: 'Z400',
      // TODO Uncomment code below when standard price is provided
      //StandardPrice: request.StandardPrice,
    },
    Classification: {
      CharacteristicName01: CharacteristicNames.TechnicalDescription,
      CharacteristicValue01: request.TechnicalDescription,
      CharacteristicName02: CharacteristicNames.Manufacturer,
      CharacteristicValue02: request.Manufacturer.slice(0, 30),
    },
    TC: {
      TCObjectType: 'MATERIALCREATEAPP',
    },
  };

  if (request.VendorInfoRequired) {
    materialData = {
      ...materialData,
      InfoRecord: {
        VendorNumber: request.Vendor,
        VendorMaterialNumber: request.VendorMaterialNumber,
        PlannedDeliveryDays: request.VendorDeliveryDays,
        Price: request.VendorPrice,
      },
    };
  }

  return materialData;
};

export const createMaterial = async (request: IMaterialRequest): Promise<string> => {
  const service = `${NEW_MATERIAL_NUMBER_SET_SERVICE}(MaterialType='${MATERIAL_TYPE_MRO_MATERIALS}',MaterialGroup='${request.MaterialGroupId}',Industry='T')`;
  const uri = buildSapApiUri(service);

  const response = await sapApi.get<ISAPResultResponse<INewMaterialNumberData>>(uri);

  const materialNumber = response.d.MatnrMaterialNumber;

  const data = mapMaterialRequestToMaterial(request, materialNumber);

  await sapApi.post<ISAPResultResponse<any>>(MATERIALS_SERVICE, data);

  return materialNumber;
};

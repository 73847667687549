import { Box, Flex, FormControl, Text, Tooltip } from '@lego/klik-ui';
import { StatusHelpBold } from '@lego/klik-ui-icons';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from '../../../i18n/intl/formatted-message';
import { IFormControl } from './form-control';

export const FormControlContainer: React.FC<PropsWithChildren<IFormControl>> = (props) => {
  const { label, isOptional, tooltip, isInvalid, children } = props;

  return (
    <FormControl isInvalid={isInvalid} marginBottom="24px">
      <Flex justifyContent="space-between">
        <Flex gap="2">
          <FormControl.Label optionalText=" " size="sm">
            {label}
          </FormControl.Label>
          {isOptional && (
            <Text color="slate.400" fontSize="sm">
              (<FormattedMessage id="formControl.optional" />)
            </Text>
          )}
        </Flex>
        {tooltip && (
          <Tooltip label={tooltip} placement="auto">
            <Box>
              <StatusHelpBold color="light-blue.500" />
            </Box>
          </Tooltip>
        )}
      </Flex>
      {children}
    </FormControl>
  );
};

import { MaterialRequestStatus } from '@material-creation/common';
import { IMaterialRequest } from '../../models/material-request';

export const MATERIAL_REQUEST_DEFAULT_VALUES: IMaterialRequest = {
  Id: '',
  MaterialNumber: '',
  Description: '',
  MaterialGroupId: '',
  OldMaterialNumber: '',
  UnitOfMeasure: '',
  Size: '',
  TechnicalDescription: '',
  IndustryStandard: '',
  IndustryStandardNumber: '',
  EanCategory: '',
  EanUpc: '',
  ABCRating: '',
  Manufacturer: '',
  ManufacturerPartNumber: '',
  Vendor: '',
  VendorMaterialNumber: '',
  VendorDeliveryDays: '',
  VendorPrice: '',
  StandardPrice: '',
  MaterialPictureFilename: '',
  MaterialPictureFileId: '',
  DatasheetFilename: '',
  DatasheetFileId: '',
  RequestStatus: MaterialRequestStatus.Pending,
  RequesterEmail: '',
  RequesterName: '',
  RejectionMessage: '',
  Plant: '500',
  StatusChangedByEmail: '',
  StatusChangedByName: '',
  VendorInfoRequired: false, // TODO Default it to true when Info Record part is working,
};

import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Image, Text } from '@lego/klik-ui';
import { getEnv } from '../../env';
import { ScreenSize } from '../../utils/screen-size';

export const FOOTER_HEIGHT = '190px';

export const Footer: React.FC = () => {
  const [isTabletView] = useMediaQuery(`(min-width: ${ScreenSize.MIN_WIDTH_TABLET})`);

  const [isDesktopView] = useMediaQuery(`(min-width: ${ScreenSize.MIN_WIDTH_DESKTOP})`);

  return (
    <Flex
      alignItems="flex-end"
      backgroundColor="white"
      bottom="0"
      height={FOOTER_HEIGHT}
      overflow="clip"
      position="fixed"
      width="full"
    >
      <Flex
        backgroundColor="light-blue.600"
        height="72px"
        justifyContent="flex-end"
        position="relative"
        width="full"
      >
        <Text alignSelf="flex-end" color="white" margin={2}>
          v{getEnv.VERSION}
        </Text>
        <Image
          bottom="50px"
          filter="invert(70%) sepia(7%) saturate(970%) hue-rotate(222deg) brightness(89%) contrast(82%)"
          height="135px"
          left="2%"
          position="absolute"
          src="/brick.svg"
          transform="rotate(-15deg)"
          width="183px"
          zIndex="-1"
        />
        {isDesktopView && (
          <Image
            bottom="-5px"
            filter="invert(76%) sepia(32%) saturate(528%) hue-rotate(177deg) brightness(94%) contrast(86%)"
            height="162px"
            left="17%"
            position="absolute"
            src="/brick.svg"
            transform="rotate(27deg)"
            width="219px"
            zIndex="-1"
          />
        )}
        {isTabletView && (
          <Image
            bottom="-3px"
            filter="invert(76%) sepia(26%) saturate(375%) hue-rotate(165deg) brightness(81%) contrast(91%)"
            height="178px"
            left="35%"
            position="absolute"
            src="/brick.svg"
            transform="rotate(7deg)"
            width="240px"
            zIndex="-1"
          />
        )}
        {isDesktopView && (
          <>
            <Image
              bottom="25px"
              filter="invert(67%) sepia(13%) saturate(531%) hue-rotate(222deg) brightness(90%) contrast(85%)"
              height="136px"
              position="absolute"
              right="30%"
              src="/brick.svg"
              transform="rotate(-2deg)"
              width="183px"
              zIndex="-1"
            />
            <Image
              bottom="25px"
              filter="invert(73%) sepia(55%) saturate(264%) hue-rotate(172deg) brightness(92%) contrast(90%)"
              height="112px"
              position="absolute"
              right="18%"
              src="/brick.svg"
              transform="rotate(-45deg)"
              width="151px"
              zIndex="-1"
            />
          </>
        )}
        <Image
          bottom="20px"
          filter="invert(74%) sepia(16%) saturate(605%) hue-rotate(164deg) brightness(87%) contrast(87%)"
          height="154px"
          position="absolute"
          right="1%"
          src="/brick.svg"
          transform="rotate(-9deg)"
          width="208px"
          zIndex="-1"
        />
      </Flex>
    </Flex>
  );
};

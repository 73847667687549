import { Image, Text } from '@lego/klik-ui';
import { IMaterialRequestDTO } from '@material-creation/common';
import * as gtin from 'gtin';
import { useContext } from 'react';
import * as Yup from 'yup';
import { IntlContext } from '../../i18n/intl/intl-context';
import { IMaterialRequest } from '../../models/material-request';
import { doesEanNumberExist, doesMaterialExist } from '../../network/search-materials';
import { useMaterialGroups } from '../../queries/material-groups';
import { nameof } from '../../utils/nameof';
import { InputFormControl } from './form-controls/input-form-control';
import { SelectFormControl } from './form-controls/select-form-control';
import { SelectInputSplitFormControl } from './form-controls/select-input-split-form-control';

enum EANCategory {
  HH = 'HH',
  GL = 'GL',
  GH = 'GH',
}

const eanCategoryGTIN = (category: string) => {
  switch (category) {
    case EANCategory.HH:
      return 'GTIN-13';
    case EANCategory.GL:
    case EANCategory.GH:
      return 'GTIN-12';
    default:
      throw new Error('Invalid EAN category');
  }
};

const isEanValid = (ean: string, category: string) => {
  try {
    if (gtin.getFormat(ean) !== eanCategoryGTIN(category)) return false;

    return gtin.isValid(ean);
  } catch (error) {
    return false;
  }
};

export const basicInformationFormValidationSchema = Yup.object<
  Partial<Record<keyof IMaterialRequest, Yup.AnySchema>>
>({
  Description: Yup.string()
    .required('validation.required')
    .max(40, 'validation.descriptionMax40Chars'),
  MaterialGroupId: Yup.string().required('validation.required'),
  OldMaterialNumber: Yup.string().test(
    nameof<IMaterialRequest>('OldMaterialNumber'),
    'validation.materialNotFound',
    async (value: string | undefined) => {
      if (!value) return true;

      return await doesMaterialExist(value);
    },
  ),
  EanCategory: Yup.string().test(
    nameof<IMaterialRequest>('EanCategory'),
    'validation.required',
    function (value?: string) {
      const eanUpc = (this.parent as IMaterialRequest).EanUpc;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (eanUpc === undefined) return true;

      return value !== undefined;
    },
  ),
  EanUpc: Yup.string().when('EanCategory', {
    is: (val?: string) => val !== undefined,
    then: (schema) =>
      schema
        .required('validation.required')
        .test(
          nameof<IMaterialRequest>('EanUpc'),
          'validation.invalidEanUpcFormat',
          function (value) {
            const eanCategory = (this.parent as IMaterialRequest).EanCategory;

            if (!value) return true;

            return isEanValid(value, eanCategory);
          },
        )
        .test(
          nameof<IMaterialRequestDTO>('EanUpc'),
          'validation.eanUpcAlreadyExist',
          async function (value) {
            const eanCategory = (this.parent as IMaterialRequestDTO).EanCategory;

            if (!value) return true;

            if (!isEanValid(value, eanCategory)) return true;

            return !(await doesEanNumberExist(eanCategory, value));
          },
        ),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const BasicInformationForm: React.FC = () => {
  const intl = useContext(IntlContext);

  const { data: materialGroups, errorMessage: materialGroupsError } = useMaterialGroups();

  const eanCategories = [EANCategory.HH, EANCategory.GL, EANCategory.GH];

  return (
    <>
      <InputFormControl
        fieldName="Description"
        label={intl.formatMessage({
          id: 'basicInformationForm.descriptionLabel',
        })}
        tooltip={
          <>
            <Text>
              {intl.formatMessage({
                id: 'basicInformationForm.descriptionLabelTooltipPart1',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'basicInformationForm.descriptionLabelTooltipPart2',
              })}
            </Text>
          </>
        }
      />
      <SelectFormControl
        data={
          materialGroups
            ?.sort((mg1, mg2) => Number(mg1.materialGroupId) - Number(mg2.materialGroupId))
            .map((materialGroup) => ({
              key: materialGroup.materialGroupId,
              value: `${materialGroup.materialGroupId} - ${materialGroup.materialGroupDescription}`,
            })) ?? []
        }
        errorMessage={materialGroupsError}
        fieldName="MaterialGroupId"
        label={intl.formatMessage({
          id: 'basicInformationForm.materialGroupLabel',
        })}
      />
      <InputFormControl
        fieldName="OldMaterialNumber"
        isOptional
        label={intl.formatMessage({
          id: 'basicInformationForm.oldMaterialNumberLabel',
        })}
        tooltip={intl.formatMessage({
          id: 'basicInformationForm.oldMaterialNumberLabelTooltip',
        })}
      />
      <SelectInputSplitFormControl
        data={eanCategories.map((category) => ({
          key: category,
          value: category,
        }))}
        inputFieldName="EanUpc"
        isOptional
        label={intl.formatMessage({ id: 'materialForm.eanUpcLabel' })}
        selectFieldName="EanCategory"
        tooltip={
          <>
            <Text>
              {intl.formatMessage({
                id: 'materialForm.eanUpcLabelTooltipPart1',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'materialForm.eanUpcLabelTooltipPart2',
              })}
            </Text>
            <Image src="/ean-upc-example.webp" />
          </>
        }
      />
    </>
  );
};

import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

interface IProtectedRouteProps {
  isRouteAllowed: boolean;
}

export const ProtectedRoute: React.FC<PropsWithChildren<IProtectedRouteProps>> = (props) => {
  const { children, isRouteAllowed } = props;

  if (!isRouteAllowed) {
    return <Navigate replace to="/" />;
  }

  return <>{children}</>;
};

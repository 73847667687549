export class SapApiError extends Error {
  public constructor(response: ISAPErrorResponse) {
    super(response.error.message.value);
    this.name = 'ServiceApiError';
  }
}

export interface ISAPErrorResponse {
  error: ISAPError;
}

interface ISAPError {
  code: string;
  message: ISAPErrorMessage;
}

interface ISAPErrorMessage {
  lang: string;
  value: string;
}

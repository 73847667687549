import { MaterialRequestStatus } from '@material-creation/common';
import { IMaterialRequest } from '../../models/material-request';
import { serviceApi } from '../../network/service-api';
import { dateSorter } from '../../utils/date-sorter';

export const getFinishedMaterialRequests = async (loadAll: boolean, fromDate: Date) => {
  let data: IMaterialRequest[] = [];

  if (loadAll) {
    const result = await Promise.all([
      serviceApi.getMaterialRequests(MaterialRequestStatus.Approved, fromDate),
      serviceApi.getMaterialRequests(MaterialRequestStatus.Rejected, fromDate),
    ]);

    data = result.flat();
  } else {
    const result = await Promise.all([
      serviceApi.getMyMaterialRequests(MaterialRequestStatus.Approved),
      serviceApi.getMyMaterialRequests(MaterialRequestStatus.Rejected),
    ]);

    data = result.flat();
  }

  return data.sort((a, b) =>
    dateSorter(
      a.UpdatedAt ? new Date(a.UpdatedAt) : undefined,
      b.UpdatedAt ? new Date(b.UpdatedAt) : undefined,
    ),
  );
};

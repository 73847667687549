import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationResult, MsalAuthenticationTemplate } from '@azure/msal-react';
import { PropsWithChildren } from 'react';
import { Spinner } from '../components/spinner/spinner';
import { ErrorPage } from '../pages/error-page/error-page';

export const AuthenticationGate: React.FC<PropsWithChildren> = ({ children }) => {
  const loadingComponent = () => <Spinner />;

  const errorComponent = (result: MsalAuthenticationResult) => (
    <ErrorPage
      errorMessage={result.error?.errorMessage ?? 'An unknown authentication error occured'}
    />
  );

  return (
    <MsalAuthenticationTemplate
      errorComponent={errorComponent}
      interactionType={InteractionType.Redirect}
      loadingComponent={loadingComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

import { MaterialRequestStatus } from '@material-creation/common';
import { useMutation, useQueryClient } from 'react-query';
import { IMaterialRequest } from '../../models/material-request';
import { updateMaterialRequest } from '../../network/update-material-request';
import { msalInstance } from '../../security/msal';
import { QueryKeys } from '../query-keys';

const pendingMaterialRequestsKey = [QueryKeys.PendingMaterialRequests];
const finishedMaterialRequestsKey = [QueryKeys.FinishedMaterialRequests];

const mutationFn = async (request: IMaterialRequest) => {
  request.RequestStatus = MaterialRequestStatus.Rejected;
  const userAccount = msalInstance.getActiveAccount()!;
  request.StatusChangedByEmail = userAccount.username;
  request.StatusChangedByName = userAccount.name ?? 'N/A';

  return updateMaterialRequest(request);
};

export const useRejectMaterialRequest = (
  onSuccess: (requestId?: string) => void,
  onError: (errorMessage?: string) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation((request: IMaterialRequest) => mutationFn(request), {
    onSuccess: (response) => {
      void queryClient.invalidateQueries(pendingMaterialRequestsKey);
      void queryClient.invalidateQueries(finishedMaterialRequestsKey);
      onSuccess(response.Id);
    },
    onError: (error) => {
      const errorMessage = error ? (error as Error).message : undefined;
      onError(errorMessage);
    },
  });
};

import { useToast } from '@lego/klik-ui';
import { ILastOpened, MaterialRequestStatus } from '@material-creation/common';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MaterialRequestForm } from '../../components/material-request/material-request-form';
import { Spinner } from '../../components/spinner/spinner';
import { showToast } from '../../components/toast/show-toast';
import { MaterialRequestRole } from '../../enums/material-request-role';
import { IntlContext } from '../../i18n/intl/intl-context';
import { IMaterialRequest } from '../../models/material-request';
import { serviceApi } from '../../network/service-api';
import { useMaterialGroups } from '../../queries/material-groups';
import { useProfile } from '../../queries/profile';
import { useUnitsOfMeasure } from '../../queries/units-of-measure';
import { msalInstance } from '../../security/msal';
import { userHasRole } from '../../utils/user-has-role';
import { ErrorPageWrapper } from '../error-page-wrapper/error-page-wrapper';
import { MATERIAL_REQUEST_DEFAULT_VALUES } from './material-request-default-values';

export const referenceParamName = 'reference';
export const requestIdParamName = 'requestId';

export const MaterialRequest: React.FC = () => {
  const lockDurationInMinutes = 10;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  const intl = useContext(IntlContext);
  const userAccount = msalInstance.getActiveAccount();
  const { data: profile } = useProfile();

  const {
    data: materialGroups,
    isLoading: isMaterialGroupsLoading,
    errorMessage: materialGroupsErrorMessage,
  } = useMaterialGroups();

  const {
    data: unitsOfMeasure,
    isLoading: isUnitsOfMeasureLoading,
    errorMessage: unitsOfMeasureErrorMessage,
  } = useUnitsOfMeasure();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<IMaterialRequest>({
    ...MATERIAL_REQUEST_DEFAULT_VALUES,
    Plant: profile?.Plant ?? '500',
    RequesterEmail: userAccount?.username ?? '',
    RequesterName: userAccount?.name ?? '',
  });

  const loadReferencedMaterial = useCallback(
    async (id: string) => {
      setIsLoading(true);

      try {
        const referencedMaterial = await serviceApi.getMaterial(id);

        const materialRequest = {
          Description: referencedMaterial.MaterialDescription,
          MaterialGroupId:
            materialGroups?.find((g) => g.materialGroupId === referencedMaterial.MaterialGroup)
              ?.materialGroupId ?? '',
          UnitOfMeasure:
            unitsOfMeasure?.find((u) => u.id === referencedMaterial.BaseUnit)?.id ?? '',
          Size: referencedMaterial.Size,
        } as IMaterialRequest;

        setInitialValues({
          ...initialValues,
          ...materialRequest,
        });
      } catch (error) {
        showToast(toast, 'top-right', {
          title: intl.formatMessage({
            id: 'materialRequest.failedToLoadRequest',
          }),
          description: (error as Error).message,
          variant: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl, toast, materialGroups, unitsOfMeasure],
  );

  const isMaterialRequestLocked = useCallback((lastOpened?: ILastOpened) => {
    if (!lastOpened) return false;
    const now = new Date();

    const unlockDate = moment(lastOpened.At).add(lockDurationInMinutes, 'minutes').toDate();

    return userAccount && lastOpened.ApproverEmail !== userAccount.username && now < unlockDate;
  }, []);

  const lockMaterialRequest = useCallback(
    async (request: IMaterialRequest) => {
      try {
        request.LastOpened = {
          ApproverEmail: userAccount?.username ?? '',
          ApproverName: userAccount?.name ?? '',
          At: new Date(),
        };

        await serviceApi.updateMaterialRequest(request);
      } catch (error) {
        showToast(toast, 'top-right', {
          title: intl.formatMessage({
            id: 'materialRequest.failedToLockRequest',
          }),
          description: (error as Error).message,
          variant: 'error',
        });
      }
    },
    [intl, toast],
  );

  const loadExistingMaterialRequest = useCallback(
    async (id: string) => {
      setIsLoading(true);

      try {
        const existingRequest = await serviceApi.getMaterialRequest(id);

        const isReviewed = existingRequest.RequestStatus !== MaterialRequestStatus.Pending;

        if (isReviewed) navigate('/');
        const isLocked = isMaterialRequestLocked(existingRequest.LastOpened);

        if (isLocked) {
          const lockerName = existingRequest.LastOpened?.ApproverName;

          showToast(toast, 'top-right', {
            title: intl.formatMessage({
              id: 'materialRequest.lockedRequest',
            }),
            description: intl.formatMessage(
              {
                id: 'materialRequest.lockedRequestMessage',
              },
              { username: lockerName ?? 'N/A' },
            ),
            variant: 'error',
          });

          navigate('/');
        } else {
          setInitialValues({
            ...MATERIAL_REQUEST_DEFAULT_VALUES,
            ...existingRequest,
          });

          void lockMaterialRequest(existingRequest);
        }
      } catch (error) {
        showToast(toast, 'top-right', {
          title: intl.formatMessage({
            id: 'materialRequest.failedToLoadRequest',
          }),
          description: (error as Error).message,
          variant: 'error',
        });

        navigate('/');
      } finally {
        setIsLoading(false);
      }
    },
    [isMaterialRequestLocked, lockMaterialRequest, navigate, toast, intl],
  );

  useEffect(() => {
    if (materialGroups && unitsOfMeasure) {
      const referencedMaterialId = searchParams.get(referenceParamName);
      const existingRequestId = searchParams.get(requestIdParamName);

      if (referencedMaterialId && userHasRole(MaterialRequestRole.Requester))
        void loadReferencedMaterial(referencedMaterialId);
      else if (existingRequestId) {
        if (userHasRole(MaterialRequestRole.Approver))
          void loadExistingMaterialRequest(existingRequestId);
        else navigate('/');
      } else if (!userHasRole(MaterialRequestRole.Requester)) navigate('/');
    }
  }, [
    loadExistingMaterialRequest,
    loadReferencedMaterial,
    navigate,
    searchParams,
    materialGroups,
    unitsOfMeasure,
  ]);

  if (isLoading || isMaterialGroupsLoading || isUnitsOfMeasureLoading)
    return <Spinner isFullHeight isIndeterminate />;

  return (
    <ErrorPageWrapper errorMessage={materialGroupsErrorMessage || unitsOfMeasureErrorMessage}>
      <MaterialRequestForm {...{ initialValues }} />
    </ErrorPageWrapper>
  );
};

import { Box, Button, Container, Text } from '@lego/klik-ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SearchMaterial } from '../../components/search-material/search-material';
import { SearchResults } from '../../components/search-material/search-results';
import { Spinner } from '../../components/spinner/spinner';
import { MaterialRequestRole } from '../../enums/material-request-role';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { useMaterialSearch } from '../../queries/material-search';
import { SearchDataResult } from '../../queries/models/material-search-result';
import { RoutePaths } from '../../routes/app-router';
import { userHasRole } from '../../utils/user-has-role';
import { ErrorPageWrapper } from '../error-page-wrapper/error-page-wrapper';

export const searchParameterName = 'q';

export const Search: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParam = searchParams.get(searchParameterName) ?? undefined;

  const navigate = useNavigate();

  const { data, errorMessage, reset, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useMaterialSearch(searchParam);

  const dataResult: SearchDataResult = {
    result: data?.pages,
    materialsCount: data?.pages.flatMap((sr) => sr.list).length,
    totalCount: data?.pages[0]?.totalCount,
    isLoading: isFetching || isFetchingNextPage,
    hasNextPage,
  };

  const onNewSearch = (value: string) => {
    setSearchParams({ q: value });
  };

  const clearErrors = () => {
    reset();
  };

  const onRequestNewMaterialClick = () => {
    navigate(`/${RoutePaths.RequestPath}`);
  };

  return (
    <ErrorPageWrapper clearErrorMessage={clearErrors} errorMessage={errorMessage}>
      <Container maxWidth="container.xl">
        <SearchMaterial initialSearchValue={searchParam} onSelect={onNewSearch} />
        <SearchResults
          dataResult={dataResult}
          loadNextPage={fetchNextPage}
          searchText={searchParam}
        />
        {dataResult.isLoading && <Spinner isIndeterminate />}
        {!dataResult.isLoading && !hasNextPage && userHasRole(MaterialRequestRole.Requester) && (
          <Box margin="24px 0" textAlign="center">
            <Text fontWeight="bold" marginBottom="8px">
              <FormattedMessage id="search.requestNewMaterialTitle" />
            </Text>
            <Button onClick={onRequestNewMaterialClick}>
              <FormattedMessage id="search.requestNewMaterial" />
            </Button>
          </Box>
        )}
      </Container>
    </ErrorPageWrapper>
  );
};

import { getMaterial } from './get-material';
import { getMaterialGroups } from './get-material-groups';
import { getMaterialRequest } from './get-material-request';
import { getMaterialRequests } from './get-material-requests';
import { getMyMaterialRequests } from './get-my-material-requests';
import { getProfile } from './get-profile';
import { getUnitsOfMeasure } from './get-units-of-measure';
import { searchMaterials } from './search-materials';
import { updateMaterialRequest } from './update-material-request';
import { updateProfile } from './update-profile';

export const serviceApi = {
  searchMaterials,
  getMaterial,
  getMaterialGroups,
  getUnitsOfMeasure,
  getMyMaterialRequests,
  getMaterialRequests,
  getMaterialRequest,
  getProfile,
  updateProfile,
  updateMaterialRequest,
};

import { PropsWithChildren } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { Spinner } from '../../components/spinner/spinner';
import { useProfile } from '../../queries/profile';
import { browserLanguage } from '../../utils/languages';
import { getTranslations } from '../translations';

export const IntlProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { isLoading, data: profile } = useProfile();

  if (isLoading) return <Spinner isFullHeight isIndeterminate />;

  const userLanguage = profile?.Language ?? browserLanguage;
  const translations = getTranslations(userLanguage);

  return (
    <ReactIntlProvider locale={userLanguage} messages={translations}>
      {children}
    </ReactIntlProvider>
  );
};

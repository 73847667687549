import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from '../components/layout/layout';
import { FormattedMessage } from '../i18n/intl/formatted-message';
import { Home } from '../pages/home/home';
import { MaterialRequest } from '../pages/material-request/material-request';
import { Profile } from '../pages/profile/profile';
import { Search } from '../pages/search/search';
import { useProfile } from '../queries/profile';
import { ProtectedRoute } from './protected-route';

export enum RoutePaths {
  SearchPath = 'search',
  RequestPath = 'request',
  ProfilePath = 'profile',
}

export const AppRouter: React.FC = () => {
  const { data: profile } = useProfile();
  const doesUserHasPlant = !!profile?.Plant;

  return (
    <Routes>
      <Route element={<Layout />} path="/">
        <Route element={<Home />} index />
        <Route
          element={
            <ProtectedRoute isRouteAllowed={doesUserHasPlant}>
              <Search />
            </ProtectedRoute>
          }
          path={RoutePaths.SearchPath}
        />
        <Route
          element={
            <ProtectedRoute isRouteAllowed={doesUserHasPlant}>
              <MaterialRequest />
            </ProtectedRoute>
          }
          path={RoutePaths.RequestPath}
        />
        <Route
          element={
            <ProtectedRoute isRouteAllowed={doesUserHasPlant}>
              <Profile />
            </ProtectedRoute>
          }
          path={RoutePaths.ProfilePath}
        />
        <Route
          element={
            <p>
              <FormattedMessage id="appRouter.pageNotFound" />
            </p>
          }
          path="*"
        />
      </Route>
    </Routes>
  );
};

import { AuthenticationResult } from '@azure/msal-browser';
import { msalInstance } from './msal';

export const refreshTokens = async (scopes: string[]): Promise<AuthenticationResult> => {
  const account = msalInstance.getActiveAccount();

  if (!account)
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.',
    );

  return msalInstance.acquireTokenSilent({
    scopes,
    account,
  });
};

import { FileUploader, Link, Progress, Stack, Text } from '@lego/klik-ui';
import { ArrowLongUp, CloudUpload } from '@lego/klik-ui-icons';
import { useState } from 'react';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { addMaterialRequestFile } from '../../network/add-material-request-file';
import { getMaterialRequestFile } from '../../network/get-material-request-file';
import { FileDownloadLink } from '../file-download-link/file-download-link';

export interface IFileUploadProps {
  mediaType: string;
  filename?: string;
  fileId?: string;
  onChange: (filename: string, fileId: string) => void;
}

export const FileUpload: React.FC<IFileUploadProps> = (props) => {
  const { mediaType, filename, fileId, onChange } = props;

  const [isInDropArea, setIsInDropArea] = useState(false);

  const [isUploadingFilename, setIsUploadingFilename] = useState<string | undefined>(undefined);

  const uploadFiles = async (files: FileList | null) => {
    if (files?.[0]) {
      const file = files[0];
      setIsUploadingFilename(file.name);
      const fileid = await addMaterialRequestFile(file);
      setIsUploadingFilename(undefined);
      onChange(file.name, fileid);
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(true);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(false);
    await uploadFiles(e.dataTransfer.files);
  };

  const renderDragAndDropArea = () => {
    if (isInDropArea) {
      return (
        <>
          <ArrowLongUp />
          <Text fontSize="md">
            <FormattedMessage id="fileUpload.dropToUpload" />
          </Text>
        </>
      );
    }

    return (
      <>
        <CloudUpload />
        <Text fontSize="md">
          <FormattedMessage id="fileUpload.dragAndDropAFile" />
        </Text>
        <Text fontSize="xs">
          <FormattedMessage id="fileUpload.or" />{' '}
          <Link isInline size="xs">
            <FormattedMessage id="fileUpload.browse" />
          </Link>{' '}
          <FormattedMessage id="fileUpload.toChooseAFile" />
        </Text>
      </>
    );
  };

  if (isUploadingFilename) {
    return (
      <Stack spacing={1}>
        <Stack direction="row" spacing={2}>
          <Text fontSize="md" fontWeight="medium">
            {isUploadingFilename}
          </Text>
        </Stack>
        <Progress borderRadius="full" isIndeterminate />
        <Text color="slate.500" fontSize="sm">
          <FormattedMessage id="fileUpload.waiting" />
        </Text>
      </Stack>
    );
  } else if (fileId) {
    return (
      <FileDownloadLink
        apiDefinition={() => getMaterialRequestFile(fileId)}
        fileName={filename ?? 'Untitled'}
      />
    );
  } else {
    return (
      <FileUploader
        isInDropArea={isInDropArea}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          accept={mediaType}
          onChange={({ target: { files } }) => void uploadFiles(files)}
          type="file"
        />
        {renderDragAndDropArea()}
      </FileUploader>
    );
  }
};

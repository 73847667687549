import { Dropdown, Grid, Input } from '@lego/klik-ui';
import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { IntlContext } from '../../../i18n/intl/intl-context';
import { IMaterialRequest } from '../../../models/material-request';
import { IKeyValue } from '../../../utils/key-value';
import { IFieldFormControl } from './form-control';
import { FormControlContainer } from './form-control-container';
import { FormControlError } from './form-control-error';

interface ISelectInputSplitFormControlProps extends Omit<IFieldFormControl, 'fieldName'> {
  selectFieldName: keyof IMaterialRequest;
  inputFieldName: keyof IMaterialRequest;
  data: IKeyValue<string, string>[];
}

export const SelectInputSplitFormControl: React.FC<ISelectInputSplitFormControlProps> = (props) => {
  const { selectFieldName, inputFieldName, errorMessage, data } = props;

  const {
    values,
    errors,
    touched,
    isValidating,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IMaterialRequest>();

  const intl = useContext(IntlContext);

  const formSetDropdownValue = async (value: string) => {
    // setFieldValue uses setState internally which is async
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await setFieldValue(selectFieldName, value);
    setFieldTouched(selectFieldName);
    handleChange(selectFieldName);
  };

  return (
    <FormControlContainer
      isInvalid={
        (!!errors[selectFieldName] && !!touched[selectFieldName] && !isValidating) || !!errorMessage
      }
      {...props}
    >
      <Grid gridTemplateColumns="40% 60%">
        <Dropdown
          defaultValue={[values[selectFieldName] as string]}
          id={selectFieldName}
          listHeight="32vh"
          onBlur={handleBlur}
          onChange={async (value) => {
            return await formSetDropdownValue(value);
          }}
          placeholder={intl.formatMessage({ id: 'selectInput.selectAnOption' })}
          size="sm"
        >
          {data.map((item) => (
            <Dropdown.Option key={item.key} value={item.key}>
              {item.value}
            </Dropdown.Option>
          ))}
        </Dropdown>
        <Input
          id={inputFieldName}
          isInvalid={
            (!!errors[inputFieldName] && !!touched[inputFieldName] && !isValidating) ||
            !!errorMessage
          }
          name={inputFieldName}
          onBlur={handleBlur}
          onChange={handleChange}
          size="sm"
          value={values[inputFieldName] as string}
        />
      </Grid>
      <FormControlError {...props} fieldName={inputFieldName} />
    </FormControlContainer>
  );
};

import { Button, Container, Flex, Grid, Image, Text } from '@lego/klik-ui';
import React from 'react';
import { FormattedMessage } from '../../i18n/intl/formatted-message';

interface IErrorPageProps {
  errorMessage: string;
  clearErrorMessage?: () => void;
}

export const ErrorPage: React.FC<IErrorPageProps> = (props) => {
  const { errorMessage, clearErrorMessage } = props;

  return (
    <Container maxWidth="container.md">
      <Flex justifyContent="space-between">
        <Grid
          gridTemplateRows="repeat(4, max-content)"
          justifyItems="start"
          marginTop="3em"
          rowGap="1em"
        >
          <Text as="h2" textStyle="h2">
            <FormattedMessage id="error.somethingIsWrong" />
          </Text>
          <Text>{errorMessage}</Text>
          {clearErrorMessage && (
            <Button onClick={clearErrorMessage} variant="outline">
              <FormattedMessage id="error.takeMeBack" />
            </Button>
          )}
        </Grid>
        <Image alignSelf="flex-end" height="420px" src="/man-injured.svg" />
      </Flex>
    </Container>
  );
};

import {
  Button,
  ButtonGroup,
  IModalProps as KlikModalProps,
  Modal as KlikModal,
} from '@lego/klik-ui';
import { PropsWithChildren, useContext } from 'react';
import { IntlContext } from '../../i18n/intl/intl-context';

interface IModalProps {
  modalTitle: string;
  confirmText?: string;
  cancelText?: string;
  isInProgress?: boolean;
  isConfirmBtnDisabled?: boolean;
  onConfirmClick?: () => void;
}

export const Modal: React.FC<Omit<KlikModalProps, 'children'> & PropsWithChildren<IModalProps>> = (
  props,
) => {
  const {
    children,
    modalTitle,
    confirmText,
    cancelText,
    isInProgress = false,
    isConfirmBtnDisabled = false,
    onConfirmClick,
    ...modalProps
  } = props;

  const intl = useContext(IntlContext);

  return (
    <KlikModal
      {...modalProps}
      closeOnEsc={!isInProgress}
      closeOnOverlayClick={!isInProgress}
      hideIcon
    >
      <KlikModal.Overlay />
      <KlikModal.Content>
        <KlikModal.Header fontSize="xl">{modalTitle}</KlikModal.Header>
        <KlikModal.CloseButton isDisabled={isInProgress} />
        <KlikModal.Body fontSize="md">{children}</KlikModal.Body>
        {onConfirmClick && (
          <KlikModal.Footer>
            <ButtonGroup>
              <Button
                colorScheme="error"
                isDisabled={isInProgress || isConfirmBtnDisabled}
                onClick={onConfirmClick}
              >
                {confirmText ?? intl.formatMessage({ id: 'confirmationModal.confirm' })}
              </Button>

              <Button isDisabled={isInProgress} onClick={modalProps.onClose} variant="outline">
                {cancelText ?? intl.formatMessage({ id: 'confirmationModal.cancel' })}
              </Button>
            </ButtonGroup>
          </KlikModal.Footer>
        )}
      </KlikModal.Content>
    </KlikModal>
  );
};

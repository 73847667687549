import { IMaterialRequestDTO } from '@material-creation/common';
import { IMaterialRequest } from '../models/material-request';

export const mapToMaterialRequest = (requestDTO: IMaterialRequestDTO): IMaterialRequest => {
  return {
    Id: requestDTO.Id,
    MaterialNumber: requestDTO.MaterialNumber,
    Description: requestDTO.Description,
    MaterialGroupId: requestDTO.MaterialGroupId,
    OldMaterialNumber: requestDTO.OldMaterialNumber,
    UnitOfMeasure: requestDTO.UnitOfMeasure,
    Size: requestDTO.Size,
    TechnicalDescription: requestDTO.TechnicalDescription,
    IndustryStandard: requestDTO.IndustryStandard,
    IndustryStandardNumber: requestDTO.IndustryStandardNumber,
    EanCategory: requestDTO.EanCategory,
    EanUpc: requestDTO.EanUpc,
    ABCRating: requestDTO.ABCRating,
    Manufacturer: requestDTO.Manufacturer,
    ManufacturerPartNumber: requestDTO.ManufacturerPartNumber,
    Vendor: requestDTO.Vendor,
    VendorMaterialNumber: requestDTO.VendorMaterialNumber,
    VendorDeliveryDays: requestDTO.VendorDeliveryDays,
    VendorPrice: requestDTO.VendorPrice,
    StandardPrice: requestDTO.StandardPrice,
    MaterialPictureFilename: requestDTO.MaterialPictureFilename,
    MaterialPictureFileId: requestDTO.MaterialPictureFileId,
    DatasheetFilename: requestDTO.DatasheetFilename,
    DatasheetFileId: requestDTO.DatasheetFileId,
    RequestStatus: requestDTO.RequestStatus,
    RequesterEmail: requestDTO.RequesterEmail,
    RequesterName: requestDTO.RequesterName,
    LastOpened: requestDTO.LastOpened,
    RejectionMessage: requestDTO.RejectionMessage,
    Plant: requestDTO.Plant,
    CreatedAt: requestDTO.CreatedAt,
    UpdatedAt: requestDTO.UpdatedAt,
    StatusChangedByEmail: requestDTO.StatusChangedByEmail,
    StatusChangedByName: requestDTO.StatusChangedByName,
  };
};

export const mapToMaterialRequestDTO = (request: IMaterialRequest): IMaterialRequestDTO => {
  return {
    Id: request.Id,
    MaterialNumber: request.MaterialNumber,
    Description: request.Description,
    MaterialGroupId: request.MaterialGroupId,
    OldMaterialNumber: request.OldMaterialNumber,
    UnitOfMeasure: request.UnitOfMeasure,
    Size: request.Size,
    TechnicalDescription: request.TechnicalDescription,
    IndustryStandard: request.IndustryStandard,
    IndustryStandardNumber: request.IndustryStandardNumber,
    EanCategory: request.EanCategory,
    EanUpc: request.EanUpc,
    ABCRating: request.ABCRating,
    Manufacturer: request.Manufacturer,
    ManufacturerPartNumber: request.ManufacturerPartNumber,
    Vendor: request.Vendor,
    VendorMaterialNumber: request.VendorMaterialNumber,
    VendorDeliveryDays: request.VendorDeliveryDays,
    VendorPrice: request.VendorPrice,
    StandardPrice: request.StandardPrice,
    MaterialPictureFilename: request.MaterialPictureFilename,
    MaterialPictureFileId: request.MaterialPictureFileId,
    DatasheetFilename: request.DatasheetFilename,
    DatasheetFileId: request.DatasheetFileId,
    RequestStatus: request.RequestStatus,
    RequesterEmail: request.RequesterEmail,
    RequesterName: request.RequesterName,
    LastOpened: request.LastOpened,
    RejectionMessage: request.RejectionMessage,
    Plant: request.Plant,
    CreatedAt: request.CreatedAt,
    UpdatedAt: request.UpdatedAt,
    StatusChangedByEmail: request.StatusChangedByEmail,
    StatusChangedByName: request.StatusChangedByName,
  };
};

import { useFormikContext } from 'formik';
import { IMaterialRequest } from '../../../models/material-request';
import { FileUpload } from '../../file-upload/file-upload';
import { IFieldFormControl } from './form-control';
import { FormControlContainer } from './form-control-container';
import { FormControlError } from './form-control-error';

interface IFileUploadFormControlProps extends Omit<IFieldFormControl, 'fieldName'> {
  fileIdFieldName: keyof IMaterialRequest;
  filenameFieldName: keyof IMaterialRequest;
}

export const FileUploadFormControl: React.FC<IFileUploadFormControlProps> = (props) => {
  const { fileIdFieldName, filenameFieldName } = props;

  const { values, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<IMaterialRequest>();

  const formSetFieldValue = async (field: string, value: string) => {
    // setFieldValue uses setState internally which is async
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await setFieldValue(field, value);
    setFieldTouched(field);
    handleChange(field);
  };

  return (
    <FormControlContainer {...props}>
      <FileUpload
        fileId={values[fileIdFieldName] as string}
        filename={values[filenameFieldName] as string}
        mediaType="image/*"
        onChange={async (filename, fileId) => {
          await formSetFieldValue(filenameFieldName, filename);
          await formSetFieldValue(fileIdFieldName, fileId);
        }}
      />
      <FormControlError {...props} fieldName={filenameFieldName} />
    </FormControlContainer>
  );
};

import { IImageProps } from '@lego/klik-ui';
import { Image } from '@lego/klik-ui-image';
import React, { useCallback, useEffect, useState } from 'react';
import { getMaterialRequestFile } from '../../network/get-material-request-file';

interface IProtectedImageProps extends IImageProps {
  fileId: string;
}

export const ProtectedImage: React.FC<IProtectedImageProps> = (props) => {
  const { fileId } = props;

  const [dataUrl, setDataUrl] = useState<string>();

  const getImage = useCallback(async () => {
    const file = await getMaterialRequestFile(fileId);
    setDataUrl(window.URL.createObjectURL(file));
  }, [fileId]);

  useEffect(() => {
    if (fileId) void getImage();
  }, [fileId, getImage]);

  return <Image {...props} src={dataUrl} />;
};

import { useQuery, useQueryClient } from 'react-query';
import { MaterialRequestRole } from '../enums/material-request-role';
import { userHasRole } from '../utils/user-has-role';
import { getMaterialRequestsMapped } from './mappers/pending-material-requests';
import { QueryKeys } from './query-keys';

const key = [QueryKeys.PendingMaterialRequests];

const queryFn = () => {
  const hasApproverRole = userHasRole(MaterialRequestRole.Approver) ?? false;

  return getMaterialRequestsMapped(hasApproverRole);
};

export const usePendingMaterialRequests = () => {
  const queryClient = useQueryClient();

  const query = useQuery(key, queryFn, {
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    errorMessage: query.error ? (query.error as Error).message : undefined,
    reset: () => queryClient.setQueryData(key, undefined),
  };
};

import { Box, Text } from '@lego/klik-ui';
import { PropsWithChildren } from 'react';

interface IFormControlGroupProps {
  title: string;
}

export const FormControlGroup: React.FC<PropsWithChildren<IFormControlGroupProps>> = (props) => {
  const { title, children } = props;

  return (
    <>
      <Text fontSize="sm" marginBottom="8px">
        {title}
      </Text>
      <Box border="1px" borderColor="lightgray" borderRadius="4px" marginBottom="24px" padding="4">
        {children}
      </Box>
    </>
  );
};

import React, { useRef } from 'react';

interface FileDownloadProps {
  apiDefinition: () => Promise<Blob>;
  onError: (errorMessage?: string) => void;
}

interface FileDownloadInfo {
  download: () => Promise<void>;
  ref: React.MutableRefObject<HTMLAnchorElement | null>;
}

export const useFileDownload = (props: FileDownloadProps): FileDownloadInfo => {
  const { apiDefinition, onError } = props;
  const ref = useRef<HTMLAnchorElement | null>(null);

  const download = async () => {
    try {
      const data = await apiDefinition();
      const urlObj = URL.createObjectURL(data);

      if (ref.current) {
        ref.current.href = urlObj;
        ref.current.click();
      }

      URL.revokeObjectURL(urlObj);
    } catch (error) {
      onError((error as Error).message);
    }
  };

  return { download, ref };
};

import { getMaterialGroups } from '../../network/get-material-groups';
import { IMaterialGroup } from '../models/material-groups';

export const getMaterialGroupsMapped = async (): Promise<IMaterialGroup[]> => {
  const data = await getMaterialGroups();

  const mappedData = data.map((r) => ({
    materialGroupId: r.MaterialGroupId,
    materialGroupDescription:
      r.Text.trim() +
      (r.Text.trim().toLowerCase() !== r.Description.trim().toLowerCase()
        ? `, ${r.Description.trim()}`
        : ''),
  }));

  return mappedData.sort((a, b) =>
    a.materialGroupDescription.localeCompare(b.materialGroupDescription),
  );
};

import { Input, ITextProps, Text } from '@lego/klik-ui';
import { SearchBold } from '@lego/klik-ui-icons';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { IntlContext } from '../../i18n/intl/intl-context';

export interface ISearchMaterialProps {
  onSelect: (value: string) => void;
  initialSearchValue?: string;
  searchDescriptionTextProps?: ITextProps;
}

export const SearchMaterial: React.FC<ISearchMaterialProps> = (props) => {
  const { onSelect, initialSearchValue, searchDescriptionTextProps } = props;

  const intl = useContext(IntlContext);

  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (initialSearchValue) setInputValue(initialSearchValue);
  }, [initialSearchValue]);

  const onKeyPress = (key: string) => {
    if (key === 'Enter' && inputValue) onSelect(inputValue);
  };

  return (
    <>
      <Input.Group>
        <Input.LeftElement>
          <SearchBold />
        </Input.LeftElement>
        <Input
          onChange={(e) => setInputValue(e.target.value)}
          onKeyUp={(e) => onKeyPress(e.key)}
          placeholder={intl.formatMessage({ id: 'searchDropdown.search' })}
          value={inputValue}
        />
      </Input.Group>
      <Text {...searchDescriptionTextProps} fontWeight="thin">
        <FormattedMessage id="searchMaterial.searchFor" />
      </Text>
    </>
  );
};

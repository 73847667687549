import axios, { AxiosError } from 'axios';
import { getEnv } from '../../env';
import { CanceledError } from '../errors/canceled-error';
import { ISAPErrorResponse, SapApiError } from '../errors/sap-api-error';
import { addAuthorizationInterceptor } from '../utils/axios-authorization-interceptor';

const axiosInstance = axios.create({
  timeout: parseInt(getEnv.SERVICE_API_TIMEOUT),
  baseURL: getEnv.BACKEND_SAP_API_BASE_URI,
});

addAuthorizationInterceptor(axiosInstance, getEnv.LEGO_AUTH_SAP_API_SCOPES.split(','));

export const get = async <T>(uri: string, abortController?: AbortController): Promise<T> => {
  return axiosInstance
    .get<T>(uri, {
      signal: abortController ? abortController.signal : undefined,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError<ISAPErrorResponse | string>) => {
      if (error.message === 'canceled') throw new CanceledError();

      if (error.response) {
        if (typeof error.response.data === 'string') {
          throw new Error(error.response.data);
        } else {
          throw new SapApiError(error.response.data);
        }
      }

      throw error;
    });
};

export const post = async <T>(uri: string, data: any): Promise<T> => {
  return axiosInstance
    .post<T>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError<ISAPErrorResponse | string>) => {
      if (error.response) {
        if (typeof error.response.data === 'string') {
          throw new Error(error.response.data);
        } else {
          throw new SapApiError(error.response.data);
        }
      }

      throw error;
    });
};

export interface ISAPPaging {
  skip: number;
  top: number;
  orderby: string;
}

export const buildSapApiUri = (
  service: string,
  filter?: string,
  select?: string,
  paging?: ISAPPaging,
  search?: string,
): string => {
  const uriFilter = filter ? `$filter=${filter}` : undefined;

  const uriSearch = search ?? undefined;

  const uriSelect = select ? `$select=${select}` : undefined;

  const uriPaging = paging
    ? `$skip=${paging.skip}&$top=${paging.top}&$inlinecount=allpages&$orderby=${paging.orderby}`
    : undefined;

  const uriFormat = '$format=json';

  return `${service}?${[uriFilter, uriSearch, uriSelect, uriPaging, uriFormat]
    .filter((value) => value !== undefined)
    .join('&')}`;
};

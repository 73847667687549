import { useMediaQuery } from '@chakra-ui/media-query';
import { Button, Divider, Flex, Text } from '@lego/klik-ui';
import { Image } from '@lego/klik-ui-image';
import { useNavigate } from 'react-router-dom';
import { MaterialRequestRole } from '../../enums/material-request-role';
import { FormattedMessage, IntlMessageKeys } from '../../i18n/intl/formatted-message';
import { referenceParamName } from '../../pages/material-request/material-request';
import { IMaterialSearchResultItemViewModel } from '../../queries/models/material-search-result';
import { RoutePaths } from '../../routes/app-router';
import { ScreenSize } from '../../utils/screen-size';
import { userHasRole } from '../../utils/user-has-role';

export interface ISearchResultsItemProps {
  item: IMaterialSearchResultItemViewModel;
}

interface IItemColumn {
  titleKey: IntlMessageKeys;
  itemKey: keyof IMaterialSearchResultItemViewModel;
}

export const SearchResultsItem: React.FC<ISearchResultsItemProps> = (props) => {
  const { item } = props;

  const navigate = useNavigate();

  const [isMobileView] = useMediaQuery(`(max-width: ${ScreenSize.MIN_WIDTH_TABLET})`);

  const onUseAsReferenceClick = (materialNumber: string) => {
    navigate({
      pathname: `/${RoutePaths.RequestPath}`,
      search: `${referenceParamName}=${materialNumber}`,
    });
  };

  const itemColumns: IItemColumn[] = [
    {
      titleKey: 'searchResultsItem.materialNumber',
      itemKey: 'materialNumber',
    },
    {
      titleKey: 'searchResultsItem.manufacturerPartNumber',
      itemKey: 'manufacturerPartNumber',
    },
    {
      titleKey: 'searchResultsItem.eanNumber',
      itemKey: 'eanNumber',
    },
    {
      titleKey: 'searchResultsItem.sizeDimension',
      itemKey: 'dimensions',
    },
    {
      titleKey: 'searchResultsItem.industryStd',
      itemKey: 'industryStd',
    },
  ];

  return (
    <Flex margin="12px 0" width="100%">
      <Image
        _hover={{ transform: 'scale(1.2)' }}
        maxWidth="128px"
        objectFit="contain"
        src="" // TODO Get material image
        transition="transform 0.2s"
      />
      <Flex flexDirection="column" marginLeft="18px" width="100%">
        <Flex
          flexDirection={isMobileView ? 'column' : 'row'}
          gap={isMobileView ? '0' : '10'}
          marginBottom="8px"
        >
          <Text fontWeight="bold">{item.description}</Text>
          <Text>{item.technicalDescription}</Text>
        </Flex>
        {isMobileView && <Divider />}
        <Flex flexDirection={isMobileView ? 'column' : 'row'}>
          {itemColumns.map((ic) => (
            <Flex
              flexDirection={isMobileView ? 'row' : 'column'}
              justifyContent={isMobileView ? 'space-between' : 'normal'}
              key={ic.itemKey}
              width={isMobileView ? 'auto' : '20%'}
            >
              <Text fontSize="small">
                <FormattedMessage id={ic.titleKey} />:
              </Text>
              <Text
                fontSize={isMobileView ? 'small' : 'medium'}
                fontWeight={isMobileView ? 'normal' : 'bold'}
              >
                {item[ic.itemKey]}
              </Text>
            </Flex>
          ))}
          {isMobileView && <Divider marginBottom="8px" />}
          <Flex alignItems="center" flexDirection="column">
            {userHasRole(MaterialRequestRole.Requester) && (
              <Button
                height="min-content"
                onClick={() => onUseAsReferenceClick(item.materialNumber)}
                size={isMobileView ? 'sm' : 'md'}
                variant="outline"
                width="min-content"
              >
                <FormattedMessage id="searchResultsItem.useAsReference" />
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

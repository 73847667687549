import { IImageProps } from '@lego/klik-ui';
import { Image } from '@lego/klik-ui-image';
import React, { useCallback, useEffect, useState } from 'react';
import { getProfilePhoto } from '../../network/get-profile-photo';

export const ProfilePhoto: React.FC<Omit<IImageProps, 'src'>> = (props) => {
  const [dataUrl, setDataUrl] = useState<string>();

  const getPhoto = useCallback(async () => {
    const photo = await getProfilePhoto();
    setDataUrl(window.URL.createObjectURL(photo));
  }, []);

  useEffect(() => {
    void getPhoto();
  }, [getPhoto]);

  if (!dataUrl) return <Image src="/avatar.svg" {...props} />;

  return <Image {...props} src={dataUrl} />;
};

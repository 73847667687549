import { IProfileDTO } from '@material-creation/common';
import { useQuery, useQueryClient } from 'react-query';
import { NotFoundError } from '../network/errors/not-found-error';
import { serviceApi } from '../network/service-api';
import { QueryKeys } from './query-keys';

const defaultProfile: IProfileDTO = {
  Language: 'en',
  Plant: '',
};

const key = [QueryKeys.Profile];

const queryFn = async () =>
  serviceApi.getProfile().catch((reason) => {
    if (reason instanceof NotFoundError) {
      return defaultProfile;
    } else {
      throw reason;
    }
  });

export const useProfile = () => {
  const queryClient = useQueryClient();

  const query = useQuery<IProfileDTO>(key, queryFn, {
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    errorMessage: query.error ? (query.error as Error).message : undefined,
    reset: () => queryClient.setQueryData(key, undefined),
  };
};

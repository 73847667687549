import { IMaterialRequestDTO, MaterialRequestStatus } from '@material-creation/common';
import { IMaterialRequest } from '../models/material-request';
import { mapToMaterialRequest } from '../utils/material-request-mapper';
import { nameof } from '../utils/nameof';
import * as materialCreationApi from './api/material-creation-api';
import { IMaterialRequestsResponse } from './material-creation-api-result-types';

export const getMaterialRequests = async (
  status: MaterialRequestStatus,
  fromDate?: Date,
): Promise<IMaterialRequest[]> => {
  let uri = `mat-requests?${nameof<IMaterialRequestDTO>('RequestStatus')}=${status}`;

  if (fromDate) uri += `&FromDate=${fromDate.toISOString()}`;

  const response = await materialCreationApi.get<IMaterialRequestsResponse>(uri);

  const materialRequestsDTO = response.Items;

  return materialRequestsDTO.map((requestDTO) => mapToMaterialRequest(requestDTO));
};

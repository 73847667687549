import { Flex, Text } from '@lego/klik-ui';
import { SearchBold } from '@lego/klik-ui-icons';
import { useContext, useState } from 'react';
import {
  components,
  DropdownIndicatorProps,
  OptionProps,
  PropsValue,
  SingleValue,
  StylesConfig,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { IntlContext } from '../../i18n/intl/intl-context';

export interface IAsyncSelectOption {
  value: string;
  label: string;
  count?: number;
}

export type TLoadOptions = (
  inputValue: string,
  callback: (options: IAsyncSelectOption[]) => void,
) => void;

interface IAsyncSelectProps {
  value: PropsValue<IAsyncSelectOption> | undefined;
  loadOptions: TLoadOptions;
  onValueChange: (option: SingleValue<IAsyncSelectOption>) => void;
  errorMessage?: string;
  padding?: string;
  margin?: string;
  fontSize?: string;
  selectedTextColor?: string;
  isInvalid?: boolean;
  placeholder?: string;
  fieldName?: string;
}

const customDropdownIndicator = (props: DropdownIndicatorProps<IAsyncSelectOption, false>) => (
  <components.DropdownIndicator {...props}>
    <SearchBold />
  </components.DropdownIndicator>
);

export const SearchDropdown: React.FC<IAsyncSelectProps> = (props) => {
  const {
    value,
    loadOptions,
    onValueChange,
    padding,
    margin,
    fontSize,
    selectedTextColor,
    errorMessage,
    isInvalid,
    placeholder,
    fieldName,
  } = props;

  const intl = useContext(IntlContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const customStyles: StylesConfig<IAsyncSelectOption, false> = {
    control: (styles) => ({
      ...styles,
      fontSize: fontSize ?? 'inherit',
      padding: padding ?? '5px',
      margin: margin ?? '10px 0',
      boxShadow: isInvalid ? '0 0 0 1px #f55b5b' : 'inherit',
      borderColor: isInvalid ? '#f55b5b' : 'inherit',
    }),
    singleValue: (base) => ({ ...base, color: selectedTextColor ?? '#969694' }),
  };

  const customOption = (optionProps: OptionProps<IAsyncSelectOption, false>) => (
    <components.Option {...optionProps}>
      <Flex justifyContent="space-between">
        <Text>{optionProps.data.label}</Text>
        {!!optionProps.data.count && (
          <Text color="light-blue.400" textAlign="right">
            [{optionProps.data.count}]
          </Text>
        )}
      </Flex>
    </components.Option>
  );

  const onLoadOptions: TLoadOptions = (inputValue, callback) => {
    setIsLoading(true);

    loadOptions(inputValue, (o) => {
      callback(o);
      setIsLoading(false);
    });
  };

  const onChange = (option: SingleValue<IAsyncSelectOption>) => {
    onValueChange(option);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && isLoading) e.preventDefault();
  };

  return (
    <AsyncSelect
      components={{
        Option: customOption,
        DropdownIndicator: customDropdownIndicator,
      }}
      id={fieldName}
      isClearable
      isMulti={false}
      loadOptions={onLoadOptions}
      noOptionsMessage={() =>
        errorMessage ?? intl.formatMessage({ id: 'searchDropdown.noResultsFound' })
      }
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder ?? intl.formatMessage({ id: 'searchDropdown.search' })}
      styles={customStyles}
      value={value}
    />
  );
};

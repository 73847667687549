import { Breadcrumb as KlikBreadcrumb, Text } from '@lego/klik-ui';
import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

export const Breadcrumb: React.FC = () => {
  const breadcrumbs = useReactRouterBreadcrumbs();

  if (breadcrumbs.length <= 1) return null;

  return (
    <KlikBreadcrumb marginLeft="90px" marginTop="20px">
      {breadcrumbs.map(({ match, breadcrumb, location }) => (
        <KlikBreadcrumb.Item key={match.pathname}>
          {location.pathname === match.pathname && <Text>{breadcrumb}</Text>}
          {location.pathname !== match.pathname && (
            <KlikBreadcrumb.Link as={RouteLink} color="light-blue.500" to={match.pathname}>
              {breadcrumb}
            </KlikBreadcrumb.Link>
          )}
        </KlikBreadcrumb.Item>
      ))}
    </KlikBreadcrumb>
  );
};

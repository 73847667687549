export const browserLanguage = navigator.language.split(/[-_]/u)[0];

export enum LanguageCode {
  English = 'en',
  Danish = 'da',
  Spanish = 'es',
  Hungarian = 'hu',
  Czech = 'cs',
  Chinese = 'zh',
}

export interface Language {
  code: string;
  name: string;
}

export const languages: Language[] = [
  { code: LanguageCode.English, name: 'English' },
  { code: LanguageCode.Danish, name: 'Dansk' },
  { code: LanguageCode.Spanish, name: 'Español' },
  { code: LanguageCode.Hungarian, name: 'Magyar' },
  { code: LanguageCode.Czech, name: 'Čeština' },
  { code: LanguageCode.Chinese, name: '中文(简体)' },
];

import { IProfileDTO } from '@material-creation/common';
import * as materialCreationApi from './api/material-creation-api';
import { IProfileResponse } from './material-creation-api-result-types';

export const getProfile = async (): Promise<IProfileDTO> => {
  const uri = 'profile';
  const response = await materialCreationApi.get<IProfileResponse>(uri);

  return response.Item;
};

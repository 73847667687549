import { IMaterialRequest } from '../models/material-request';
import { mapToMaterialRequest } from '../utils/material-request-mapper';
import * as materialCreationApi from './api/material-creation-api';
import { IMaterialRequestResponse } from './material-creation-api-result-types';

export const getMaterialRequest = async (id: string): Promise<IMaterialRequest> => {
  const uri = `mat-requests/${id}`;
  const response = await materialCreationApi.get<IMaterialRequestResponse>(uri);

  const materialRequestDTO = response.Item;

  return mapToMaterialRequest(materialRequestDTO);
};

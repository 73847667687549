import { Input } from '@lego/klik-ui';
import { useFormikContext } from 'formik';
import { IMaterialRequest } from '../../../models/material-request';
import { IFieldFormControl } from './form-control';
import { FormControlContainer } from './form-control-container';
import { FormControlError } from './form-control-error';

interface IInputWithAddonFormControlProps extends IFieldFormControl {
  addonText: string;
  onValueChange?: (value: string) => void;
}

export const InputWithAddonFormControl: React.FC<IInputWithAddonFormControlProps> = (props) => {
  const { fieldName, errorMessage, addonText, onValueChange } = props;

  const { values, errors, touched, isValidating, handleChange, handleBlur } =
    useFormikContext<IMaterialRequest>();

  return (
    <FormControlContainer {...props}>
      <Input.Group size="sm">
        <Input
          id={fieldName}
          isInvalid={
            (!!errors[fieldName] && !!touched[fieldName] && !isValidating) || !!errorMessage
          }
          name={fieldName}
          onBlur={(e) => {
            handleBlur(e);
            if (onValueChange) onValueChange(e.target.value);
          }}
          onChange={handleChange}
          value={values[fieldName] as string}
        />
        <Input.RightAddon>{addonText}</Input.RightAddon>
      </Input.Group>
      <FormControlError {...props} />
    </FormControlContainer>
  );
};

import { IMaterialRequest } from '../models/material-request';
import { mapToMaterialRequestDTO } from '../utils/material-request-mapper';
import * as materialCreationApi from './api/material-creation-api';
import { IUpdatedMaterialRequestResponse } from './material-creation-api-result-types';

export const updateMaterialRequest = async (
  materialRequest: IMaterialRequest,
): Promise<IUpdatedMaterialRequestResponse> => {
  const uri = `mat-requests/${materialRequest.Id}`;

  const materialRequestDTO = mapToMaterialRequestDTO(materialRequest);

  return materialCreationApi.put<typeof materialRequestDTO, IUpdatedMaterialRequestResponse>(
    uri,
    materialRequestDTO,
  );
};

import { useMediaQuery } from '@chakra-ui/media-query';
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Input,
  Text,
  useDisclosure,
  useToast,
} from '@lego/klik-ui';
import { Formik, FormikErrors } from 'formik';
import moment from 'moment';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { MaterialRequestRole } from '../../enums/material-request-role';
import { FormattedMessage } from '../../i18n/intl/formatted-message';
import { IntlContext } from '../../i18n/intl/intl-context';
import { IMaterialRequest } from '../../models/material-request';
import { sendMaterialRequestStatusEmailToRequester } from '../../network/send-status-email';
import { useApproveMaterialRequest } from '../../queries/mutations/approve-material-request';
import { useMutateCreateMaterialRequest } from '../../queries/mutations/create-material-request';
import { useRejectMaterialRequest } from '../../queries/mutations/reject-material-request';
import { ScreenSize } from '../../utils/screen-size';
import { userHasRole } from '../../utils/user-has-role';
import { FOOTER_HEIGHT } from '../footer/footer';
import { Modal } from '../modal/modal';
import { showToast } from '../toast/show-toast';
import {
  BasicInformationForm,
  basicInformationFormValidationSchema,
} from './basic-information-form';
import { FileUploadFormControl } from './form-controls/file-upload-form-control';
import { ManufacturerForm, manufacturerFormValidationSchema } from './manufacturer-form';
import { MaterialForm, materialFormValidationSchema } from './material-form';
import { Step, Stepper } from './stepper/stepper';
import { VendorForm, vendorFormValidationSchema } from './vendor-form';

interface IMaterialRequestFormProps {
  initialValues: IMaterialRequest;
}

export const MaterialRequestForm: React.FC<IMaterialRequestFormProps> = (props) => {
  const { initialValues } = props;

  const navigate = useNavigate();
  const toast = useToast();
  const intl = useContext(IntlContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [activeStep, setActiveStep] = useState<number>(1);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  const [currentValidationSchema, setCurrentValidationSchema] = useState(
    basicInformationFormValidationSchema,
  );

  const [isNotMobileView] = useMediaQuery(`(min-height: ${ScreenSize.MAX_HEIGHT_MOBILE})`);

  const formButtonsContainerRef = useRef<HTMLDivElement>(null);

  const onHandleMaterialRequestSuccess = async (
    requestId?: string,
    sendEmailNotification = false,
  ) => {
    navigate('/');

    showToast(toast, 'top-right', {
      title: intl.formatMessage({
        id: 'materialRequestForm.materialRequest',
      }),
      description: intl.formatMessage({
        id: 'materialRequestForm.materialRequestHandled',
      }),
      variant: 'success',
    });

    if (sendEmailNotification && requestId)
      try {
        await sendMaterialRequestStatusEmailToRequester(requestId);
      } catch (err) {
        showToast(toast, 'top-right', {
          title: intl.formatMessage({
            id: 'materialRequestForm.materialRequest',
          }),
          description: intl.formatMessage({
            id: 'materialRequestForm.materialRequestEmailNotificationError',
          }),
          variant: 'warning',
        });
      }
  };

  const onHandleMaterialRequestError = (error?: string) => {
    if (error) {
      showToast(toast, 'top-right', {
        title: intl.formatMessage({
          id: 'materialRequestForm.materialRequestNotHandled',
        }),
        description: error,
        variant: 'error',
      });
    }
  };

  const { mutate: createMaterialRequest, isLoading: isCreateMaterialRequestInProgress } =
    useMutateCreateMaterialRequest(onHandleMaterialRequestSuccess, onHandleMaterialRequestError);

  const { mutate: approveMaterialRequest, isLoading: isApproveMaterialRequestInProgress } =
    useApproveMaterialRequest(
      (requestId) => onHandleMaterialRequestSuccess(requestId, true),
      onHandleMaterialRequestError,
    );

  const { mutate: rejectMaterialRequest, isLoading: isRejectMaterialRequestInProgress } =
    useRejectMaterialRequest(
      (requestId) => onHandleMaterialRequestSuccess(requestId, true),
      onHandleMaterialRequestError,
    );

  const doesUserHasApprovalRole = useMemo(() => userHasRole(MaterialRequestRole.Approver), []);

  const isInTheApprovalProcess = useMemo(
    () => initialValues.Id && doesUserHasApprovalRole,
    [initialValues.Id, doesUserHasApprovalRole],
  );

  const steps = useMemo(() => {
    const list: Step[] = [
      {
        title: 'Basic information',
        component: <BasicInformationForm />,
        validationSchema: basicInformationFormValidationSchema,
      },
      {
        title: 'Material',
        component: <MaterialForm />,
        validationSchema: materialFormValidationSchema,
      },
      {
        title: 'Manufacturer',
        component: <ManufacturerForm />,
        validationSchema: manufacturerFormValidationSchema,
      },
    ];

    if (isInTheApprovalProcess) {
      list.push({
        title: 'Vendor',
        component: <VendorForm />,
        validationSchema: vendorFormValidationSchema,
      });
    }

    return list;
  }, [isInTheApprovalProcess]);

  const isLastStep = useMemo(() => activeStep === steps.length, [activeStep, steps.length]);

  const goToNextStep = useCallback(() => {
    setActiveStep(Math.min(activeStep + 1, steps.length));
    setCompletedSteps((cSteps) => [activeStep, ...cSteps]);
  }, [activeStep, steps]);

  const goToPreviousStep = useCallback(() => {
    setActiveStep(Math.max(activeStep - 1, 1));
    setCompletedSteps((cSteps) => cSteps.filter((s) => s !== activeStep));
  }, [activeStep]);

  const onValidationSchemaChange = (schema: Yup.AnyObjectSchema) => {
    setCurrentValidationSchema(schema);
  };

  const onFormSubmit = (data: IMaterialRequest) => {
    if (isLastStep) {
      if (isInTheApprovalProcess) {
        approveMaterialRequest(data);
      } else {
        createMaterialRequest(data);
      }
    } else {
      goToNextStep();
    }
  };

  const onRejectRequestClick = (data: IMaterialRequest) => {
    rejectMaterialRequest(data);
  };

  const nextStepBtnText = useCallback(
    (isValidating: boolean, isSubmitting: boolean) => {
      if (isValidating)
        return intl.formatMessage({
          id: 'materialRequestForm.validatingRequest',
        });

      if (!isLastStep) return intl.formatMessage({ id: 'materialRequestForm.next' });
      else if (isInTheApprovalProcess)
        return isSubmitting
          ? intl.formatMessage({ id: 'materialRequestForm.creatingMaterial' })
          : intl.formatMessage({ id: 'materialRequestForm.createMaterial' });
      else
        return isSubmitting
          ? intl.formatMessage({ id: 'materialRequestForm.sendingRequest' })
          : intl.formatMessage({ id: 'materialRequestForm.sendRequest' });
    },
    [intl, isInTheApprovalProcess, isLastStep],
  );

  const scrollToElement = (errors: FormikErrors<IMaterialRequest> | undefined) => {
    if (errors) {
      const fieldName = Object.keys(errors)[0];
      const element = document.getElementById(fieldName);

      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 1);
      }
    }
  };

  return (
    <>
      <Container maxWidth="container.xl">
        {isInTheApprovalProcess && (
          <Text color="slate.500" fontSize="sm" textAlign="center">
            <FormattedMessage
              id="materialRequestForm.sentOn"
              values={{
                date: moment(initialValues.CreatedAt).format('YYYY-MM-DD'),
                username: initialValues.RequesterName,
              }}
            />
          </Text>
        )}
        <Text as="h3" marginBottom="12px" textAlign="center" textStyle="h3">
          {isInTheApprovalProcess ? (
            <FormattedMessage id="materialRequestForm.finishMaterial" />
          ) : (
            <FormattedMessage id="materialRequestForm.requestNewMaterial" />
          )}
        </Text>
        <Divider marginBottom="32px" />
      </Container>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validateOnChange={false}
        validationSchema={currentValidationSchema}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <Container
              maxWidth="container.xl"
              paddingBottom={`${formButtonsContainerRef.current?.clientHeight ?? 0}px`}
            >
              <Flex flexDirection="column">
                <Stepper
                  {...{ steps, activeStep, completedSteps }}
                  onValidationSchemaChange={onValidationSchemaChange}
                >
                  <Box opacity="40%" pointerEvents="none">
                    <FileUploadFormControl
                      fileIdFieldName="MaterialPictureFileId"
                      filenameFieldName="MaterialPictureFilename"
                      isOptional
                      label={intl.formatMessage({
                        id: 'materialRequestForm.materialPictureLabel',
                      })}
                    />
                    <FileUploadFormControl
                      fileIdFieldName="DatasheetFileId"
                      filenameFieldName="DatasheetFilename"
                      isOptional
                      label={intl.formatMessage({
                        id: 'materialRequestForm.dataSheetLabel',
                      })}
                    />
                  </Box>
                </Stepper>
              </Flex>
            </Container>
            <Box
              backgroundColor="white"
              bottom={isNotMobileView ? FOOTER_HEIGHT : 0}
              position="fixed"
              ref={formButtonsContainerRef}
              width="100%"
            >
              <Container marginBottom="24px" marginTop="24px" maxWidth="container.xl">
                <Divider marginBottom="24px" />
                <Flex justifyContent="space-between">
                  <Button
                    onClick={goToPreviousStep}
                    variant="outline"
                    visibility={activeStep > 1 ? 'visible' : 'hidden'}
                  >
                    <FormattedMessage id="materialRequestForm.back" />
                  </Button>
                  <Flex gap="4">
                    {isInTheApprovalProcess && (
                      <>
                        <Button colorScheme="error" onClick={onOpen} variant="outline">
                          <FormattedMessage id="materialRequestForm.reject" />
                        </Button>
                        <Modal
                          confirmText={intl.formatMessage({
                            id: 'materialRequestForm.reject',
                          })}
                          isConfirmBtnDisabled={!formProps.values.RejectionMessage}
                          isInProgress={isRejectMaterialRequestInProgress}
                          isOpen={isOpen}
                          modalTitle={intl.formatMessage({
                            id: 'materialRequestForm.rejectRequest',
                          })}
                          onClose={onClose}
                          onConfirmClick={() => onRejectRequestClick(formProps.values)}
                          size="xl"
                          variant="warning"
                        >
                          <FormattedMessage id="materialRequestForm.rejectRequestMessage" />
                          <Box marginTop="8px">
                            <Input
                              isInvalid={!formProps.values.RejectionMessage}
                              name="RejectionMessage"
                              onBlur={formProps.handleBlur}
                              onChange={formProps.handleChange}
                              placeholder={intl.formatMessage({
                                id: 'materialRequestForm.rejectionMessage',
                              })}
                              value={formProps.values.RejectionMessage}
                            />
                          </Box>
                        </Modal>
                      </>
                    )}
                    <Button
                      isDisabled={
                        formProps.isValidating ||
                        isCreateMaterialRequestInProgress ||
                        isApproveMaterialRequestInProgress
                      }
                      onClick={() => scrollToElement(formProps.errors)}
                      type="submit"
                    >
                      {nextStepBtnText(
                        formProps.isValidating,
                        isCreateMaterialRequestInProgress || isApproveMaterialRequestInProgress,
                      )}
                    </Button>
                  </Flex>
                </Flex>
              </Container>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

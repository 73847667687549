import { MaterialRequestStatus } from '@material-creation/common';
import { IMaterialRequest } from '../../models/material-request';
import { serviceApi } from '../../network/service-api';
import { dateSorter } from '../../utils/date-sorter';

export const getMaterialRequestsMapped = async (loadAll: boolean): Promise<IMaterialRequest[]> => {
  let data: IMaterialRequest[] = [];
  if (loadAll) data = await serviceApi.getMaterialRequests(MaterialRequestStatus.Pending);
  else data = await serviceApi.getMyMaterialRequests(MaterialRequestStatus.Pending);

  return data.sort((a, b) =>
    dateSorter(
      a.CreatedAt ? new Date(a.CreatedAt) : undefined,
      b.CreatedAt ? new Date(b.CreatedAt) : undefined,
    ),
  );
};
